export const SuccessIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
      <mask id="mask0_89_3399" maskUnits="userSpaceOnUse" x="0" y="0" width="25" height="25">
        <rect x="0.785645" y="0.846191" width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_89_3399)">
        <path
          d="M11.3856 17.4462L18.4356 10.3962L17.0356 8.99619L11.3856 14.6462L8.53564 11.7962L7.13564 13.1962L11.3856 17.4462ZM12.7856 22.8462C11.4023 22.8462 10.1023 22.5837 8.88564 22.0587C7.66898 21.5337 6.61064 20.8212 5.71064 19.9212C4.81064 19.0212 4.09814 17.9629 3.57314 16.7462C3.04814 15.5295 2.78564 14.2295 2.78564 12.8462C2.78564 11.4629 3.04814 10.1629 3.57314 8.94619C4.09814 7.72952 4.81064 6.67119 5.71064 5.77119C6.61064 4.87119 7.66898 4.15869 8.88564 3.63369C10.1023 3.10869 11.4023 2.84619 12.7856 2.84619C14.169 2.84619 15.469 3.10869 16.6856 3.63369C17.9023 4.15869 18.9606 4.87119 19.8606 5.77119C20.7606 6.67119 21.4731 7.72952 21.9981 8.94619C22.5231 10.1629 22.7856 11.4629 22.7856 12.8462C22.7856 14.2295 22.5231 15.5295 21.9981 16.7462C21.4731 17.9629 20.7606 19.0212 19.8606 19.9212C18.9606 20.8212 17.9023 21.5337 16.6856 22.0587C15.469 22.5837 14.169 22.8462 12.7856 22.8462Z"
          fill="#13CE66"
        />
      </g>
    </svg>
  );