import React, { useState, useEffect, useCallback } from 'react';
import axios from '../axiosConfig';
import useProfileData from '../services/ProfileDataService';
import { getCurrentLine} from '../services/CurrentLineService';
import ArrowIcon from './Atoms/Icons/ArrowIcon'

const CheckboxMAW = ({ endPointData, field, label, onSelectionChange, currentFilterField, onRemoveAll, currentSelectedOptions, selectedFilter, removeFilter, prevFilter, filter, onChangeFilter }) => {

  
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [columnOptions, setColumnOptions] = useState(endPointData);  
  
      const fieldValues = (columnOptions?.find(column => column.columnName === field)?.uniqueValues) ?? [];
      const updatedEndPoints = fieldValues.map((value, index) => ({
        key: index,
        field: value
      }));
  
      const allOptions = updatedEndPoints;      
      const filteredOptions = allOptions.filter(option => (typeof option.field === 'string' || typeof option.field === 'number'));
  
      const filteredBooleanObject = filter
      ? filter.reduce((acc, curr) => {
          if (curr.field === currentFilterField) {
            acc[curr.value] = true;
          }
          return acc;
        }, {})
      : {};
  
      const [checkboxState, setCheckboxState] = useState(filteredBooleanObject);
  
      // **************Keep options selected on the CURRENT SELECTION**************
      const currentFilter = currentSelectedOptions
        ? currentSelectedOptions.reduce((acc, option) => ({ ...acc, [option]: true }), {})
        : {};
  
      const [checkboxStateCurrent, setCheckboxStateCurrent] = useState(currentFilter);
  
      const handleCheckboxChangeCurrent = (field) => {
        const newState = { ...checkboxStateCurrent, [field]: !checkboxStateCurrent[field] };
        setCheckboxStateCurrent(newState);
  
        const selectedOptions = Object.keys(newState).filter((key) => newState[key]);
        setSelectedOptions(selectedOptions);  
        // Reevaluar el objeto filtrado con las nuevas selecciones
        const updatedFilteredObject = {
          ...filteredBooleanObject,
          ...selectedOptions.reduce((acc, option) => {
            if (!filteredBooleanObject[option]) {
              acc[option] = true;
            }
            return acc;
          }, {})
        };
  
      };
      // **************Keep options selected on the CURRENT SELECTION**************
  
      const handleClear = () => {
        setCheckboxState({});
        setSelectedOptions([]);
  
        if (onRemoveAll) {
          onRemoveAll(currentFilterField);
          setTimeout(() => {
            onRemoveAll(currentFilterField);
          }, 0);
        }
      };
  
      useEffect(() => {
        setCheckboxState(filteredBooleanObject);
      }, []);
  
      const [show, setShow] = useState(true);
      const { token } = useProfileData(false);
  
      const fetchFiltersData = async () => {
        const prductName= getCurrentLine().ProdFamilyName;
        const ProdFamilyId= getCurrentLine().ProdFamilyId;
        
        if (token) {
            const response = await axios.get(
                `/Department/GetFilterOptions/${encodeURIComponent(ProdFamilyId)}?plantProdFamilyId=${encodeURIComponent(getCurrentLine().PlantProdFamilyId)}&columnNameDistinct=${encodeURIComponent(field)}&ViewOption=${encodeURIComponent(0)}&productName=${encodeURIComponent(prductName)}`,            
                {
                    headers: {
                        Authorization: token,
                    },
                }
            );
    
            setColumnOptions(response.data);               
        }
      };    
  
      const onClick = () => {
        setShow(!show);
  
        if(show)
          fetchFiltersData();      
      };
    
      // Define the callback using useCallback
      const handleOptionsChange = useCallback(() => {
        const newValue = selectedOptions;
        if (onChangeFilter) {
          onChangeFilter(field, newValue);
        }
      }, [onChangeFilter, selectedOptions]);
  
      // Use the callback inside useEffect
      useEffect(() => {
        handleOptionsChange();
      }, [selectedOptions]);
  
      const containerStyles = label !== 'none' ? {
          padding: '16px',
          background: '#FFF',
          height: 'inherit',
          width: 'inherit',
          filter: 'drop-shadow(0px 0px 15px rgba(0, 0, 0, 0.09))',
          borderBottom: '1px solid #CFDFDF'
        } : {};
  
    return (
      <div style={containerStyles}>
      { label !== 'none' && (
        <div style = {{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
          <div className='roboto-15-700' style={{textAlign: 'left'}}>{label}</div>
          <button style={{background: 'transparent', width: '19px'}} onClick={onClick}><ArrowIcon show={show}/></button>
        </div>
      )}
        {!(label !== 'none' && show) && (
        <div>
        {filteredOptions.map((option) => (
          <div
            className="roboto-15-400"
            key={option.field}
            style={{
              padding: '8px 0px',
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'row',
              alignContent: 'baseline',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              // cursor: 'pointer'
            }
          }
            title= {option.field}
          >
            <div
              key={option.key}
              style={{
                flex: '1',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                marginRight: '10px',
              }}
            >
              {option.field}
            </div>
            {/* CURRENT SELECTION */}
            <label className="custom-checkbox">
              <input
                type="checkbox"
                checked={checkboxStateCurrent[option.field]}
                onChange={() => handleCheckboxChangeCurrent(option.field)}
                style={{ display: 'none' }}
              />
              <div
                className={`checkbox-icon ${
                  checkboxStateCurrent[option.field] ? 'checked' : ''
                }`}
                style={{ flex: 'none' }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="24"
                  viewBox="0 -960 960 960"
                  width="24"
                  fill="white"
                >
                  <path d="M382-240 154-468l57-57 171 171 367-367 57 57-424 424Z" />
                </svg>
              </div>
            </label>
            {/* CURRENT SELECTION */}
          </div>
        ))}
          </div>
        )}
      </div>
    );
  };
  export default CheckboxMAW;