import React, { useState, useEffect } from 'react';
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';
import DraftHoursGrid from './DraftHoursGrid';
import { Generalcolumns, DraftHoursColumns, CancelledShippedColumns } from './Columns';
import './TabStripComponent.css'
import { Chip, ChipList } from "@progress/kendo-react-buttons";
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { SvgIcon } from "@progress/kendo-react-common";
import { chevronLeftIcon} from "@progress/kendo-svg-icons";

import { xIcon } from "@progress/kendo-svg-icons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Form, Field, FormElement, FieldWrapper } from "@progress/kendo-react-form";
import { NumericTextBox, TextArea } from "@progress/kendo-react-inputs";
import { Label, Hint } from "@progress/kendo-react-labels";

import Modal from './Modal';
import Toast from './ToastNotification';

import axios from '../axiosConfig';
import { truncateEmail } from '../Utilities/utils';
import { useMsal } from "@azure/msal-react";
import useProfileData from '../services/ProfileDataService';
import { formatDate } from '../Utilities/utils';
import useEmployeeRole from '../Utilities/EmployeeRole';

const formatBuildWeek = (dateString) => {
  const date = new Date(dateString);
  return date.toISOString().split('T')[0];
};

const minValueValidator = (value) => (value >= 0 ? "" : "The value must be 0 or higher");

const NonNegativeNumericInput = (fieldRenderProps) => {
  const [toast, setToast] = useState(false);
  const [idtoast, setIdtoast] = useState(1);
  const { disabled2, validationMessage, visited, id, valid, label, ...others } = fieldRenderProps;
  
  useEffect(() => {
    if (fieldRenderProps.modified == true && fieldRenderProps.value < 0) {
      setIdtoast(idtoast + 1);
      setToast({ showToast: false });
      setToast({ showToast: true, message: 'The value must be 0 or higher', type: 'error' });
    }
  }, [fieldRenderProps.validationMessage != ""]);

  return (
    <>
    {toast && <Toast key={idtoast} showToast={toast.showToast} message={toast.message} type={toast.type} />}
      <Label editorId={id} editorValid={valid} className={"k-form-label"}>
        {label}
      </Label>
      <div className={"k-form-field-wrap"}>
        <NumericTextBox spinners={disabled2} {...others} />
      </div>
    </>
  );
};

export const requiredValidator = (value) =>
  value ? "" : "Error: This field is required.";

export const FormTextArea = (fieldRenderProps) => {
  const { validationMessage, touched, label, id, valid, hint, disabled, optional, ...others} = fieldRenderProps;
  const max = 500;

  return (
    <FieldWrapper>
      <div className={"k-form-field-wrap"}>
        <Label editorId={id} editorValid={valid} className={"k-form-label"}>
          {label}
        </Label>
        <TextArea
          style={{ height: '100px'}}
          valid={valid}
          id={id}
          disabled= {disabled}
          maxLength={max}
          rows={10}
          {...others}
        />
        <Hint direction={"end"}>
          {fieldRenderProps.value?.length > 0 ? `${fieldRenderProps.value?.length} / ${max}` : `0 / ${max}`}
        </Hint>
      </div>
    </FieldWrapper>
  );
};

const EditForm = (props) => {
  const { formRenderProps, cancelEdit, item, disabled } = props;

  return (
    <FormElement className='roboto-15-500' style={{ width: '100%', marginTop: '-16px'}}>
      <div style={{ display: 'flex', flexDirection: 'row', gap: '16px' }}>
        <FieldWrapper>
          <Field width={100} name={"capacity"} component={NonNegativeNumericInput} label={"Capacity"} validator={minValueValidator} />
        </FieldWrapper>
        <FieldWrapper>
          <Field width={100} name={"adjustedHours"} component={NonNegativeNumericInput} label={"Adjusted hours"} validator={minValueValidator} />
        </FieldWrapper>
        <FieldWrapper>
          <Field width={100} name={"reservedHours"} component={NonNegativeNumericInput} label={"Reserved hours"} validator={minValueValidator} />
        </FieldWrapper>
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', gap: '16px' }}> 
        <FieldWrapper style={{ width: '100%'}}>
            <Field name={"notes"} component={FormTextArea} label={"Notes"} />
        </FieldWrapper>
        <FieldWrapper style={{ width: '100%'}}>
            <Field name={"reservedJobDesc"} component={FormTextArea} label={"Reserved jobs description"} />
        </FieldWrapper>
      </div>
      <div style={{marginTop: '24px', marginBottom: '-24px'}}>
      <DialogActionsBar layout="end">
        <Button className='secondary-button blue-text roboto-15-500' 
          onClick={cancelEdit}>
          Close
        </Button>
        <Button
          className='primary-button roboto-15-500'
          type={"submit"}
          themeColor={"primary"}
          // disabled={!submit}
        >
          Save
        </Button>
      </DialogActionsBar>
      </div>
    </FormElement>
  );
};

const TabComponentDraftHours = () => {
  const [selected, setSelected] = useState(0);
  const [dialogVisible, setDialogVisible] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState([...Generalcolumns]);
  const { employeeRole, employeeData } = useEmployeeRole();
  const [dateRange, setDateRange] = useState({ startDate: null, endDate: null });

  const handleDateRangeChange = (startDate, endDate) => {
    setDateRange({ startDate, endDate });
  };

  const [removeFilter, setRemoveFilter] = useState(false)
  const [dropdownFilters, setDropdownFilters] = useState([])


  const handleSelect = (e) => {
    setSelected(e.selected);
    switch (e.selected) {
      case 0:
        setSelectedColumns([...Generalcolumns]);
        handleResetTableFilters();
        break;
      case 1:
        setSelectedColumns([...DraftHoursColumns]);
        handleResetTableFilters();
        break;
      case 2:
        setSelectedColumns([...CancelledShippedColumns]);
        handleResetTableFilters();
        break;
      default:
        break;
    }
  };

  let allColumnss;
  switch (selected) {
      case 0:
          allColumnss = Generalcolumns;
          break;
      case 1:
        // neworders
          allColumnss = DraftHoursColumns;
          break;
      case 2:
          allColumnss = CancelledShippedColumns;
          break;
      default:
          allColumnss = Generalcolumns;
          break;
  }

  const handleSaveColumns = (columnField) => {
    setSelectedColumns(prevSelectedColumns => {
      return prevSelectedColumns.map(column => {
        if (column.field === columnField) {
          return { ...column, visible: !column.visible };
        }
        return column;
      });
    });
  };

  const handleDialogClose = () => {
    setDialogVisible(false);
  };

  const [filter, setFilter] = useState(null);
  const [prevFilter, setPrevFilter] = useState([]);
  const [currentFilter, setCurrentFilter] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [emptyDate, setEmptyDate] = useState(startDate !== null);

  const handleResetTableFilters = () => {
    setFilter(null);
    setPrevFilter([]);
    setCurrentFilter([]);
  };

  const handleResetFilter = () => {
    handleResetTableFilters();
    setStartDate(null);
    setEndDate(null);
    setDateRange({ startDate: null, endDate: null });
    localStorage.removeItem('startDate');
    localStorage.removeItem('endDate');
    setEmptyDate(true);
  }

  const [size, setSize] = React.useState("medium");
  const [fillMode, setFillMode] = React.useState("solid");
  const [rounded, setRounded] = React.useState("full");
  const dateFields = ["buildWeek", "draftingStartDate", "draftingDueDate", "loadDate"];

  const groupedFilters = filter && filter.filters ? filter.filters.reduce((acc, curr) => {
    if (!acc[curr.field]) {
      acc[curr.field] = [];
    }

    let formattedValue = curr.value;
    if (typeof curr.value === 'boolean') {
      // formating boolean value
      formattedValue = curr.value ? "Yes" : "No";
    } else if (dateFields.includes(curr.field)) {
      // formating date value 
      formattedValue = new Date(curr.value) instanceof Date && !isNaN(new Date(curr.value))
        ? new Date(curr.value).toLocaleDateString('en-US')
        : curr.value;
    }

    acc[curr.field].push(formattedValue);
    return acc;
  }, {}) : {};

  const clearFilter = (field) => {    
    handleRemoveAllFilters(field);
  };
    
  const extraData = { filtersLabel: ["Active filters:"]};

  const chipsData = {
    ...extraData,
    ...groupedFilters
  };

  
  const handleRemoveAllFilters = (currentFilterField) => {
    setFilter((prevFilter) => {
      if (!prevFilter) {
        return null;
      }

      // Remove the filter related to the current custom filter field
      const updatedFilters = prevFilter.filters.filter((filter) => filter.field !== currentFilterField);

      // If there are no filters left, return null to clear the filter
      return updatedFilters.length > 0 ? { ...prevFilter, filters: updatedFilters } : null;
    });

    setRemoveFilter(true);
    const allFields = filter?.filters?.map(filter => filter.field) || [];
    const updatedFields = allFields.filter(item => item !== currentFilterField);
    const allFields2 = [...new Set(updatedFields)];
    setPrevFilter(allFields2);
    setDropdownFilters(filter?.filters);
  };

  
  const [exportExcel, setExportExcel] = useState(false);


  // Edit drafting week form
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [openForm, setOpenForm] = useState(false);
  const [openMultipleForm, setOpenMultipleForm] = useState(false);
  const [editItem, setEditItem] = useState();
  const [modalVisible, setModalVisible] = useState(false);
  const {accounts} = useMsal();
  const { token, profileImageData } = useProfileData();
  const [toast, setToast] = useState(null);
  const [idtoast, setIdtoast] = useState(1);
  const emptyForm = {capacity: "0", adjustedHours: "0", reservedHours: "0", notes: "", reservedJobDesc: ""}

  const handleEditSelected = () => {

    if (selectedOptions?.length == 1) {
      setOpenForm(true);
      setEditItem(selectedOptions[0]);
    }
    else if (selectedOptions?.length > 1) {
      setModalVisible(true);
      setEditItem(emptyForm);
    }
    else if (selectedOptions?.length == 0) {
      showToastSelection();
    }
  }
  
  const handleMultipleEdit = () => {
    setOpenMultipleForm(true);
    setModalVisible(false);
  };

  const [drafthoursEdit, setDrafthoursEdit] = useState(false);
    
  const handleCancelEdit = () => {
    setOpenForm(false);
    setOpenMultipleForm(false);
  };

  const showToastError = async (error) => {

    let errorMessage = error.request.response ? error.request.response : 'An unexpected error occurred';
    errorMessage = errorMessage.replace(/['"]+/g, '');

    setIdtoast(idtoast + 1);
    setToast({ showToast: false });
    setToast({ showToast: true, message: errorMessage, type: 'error' });
  };

  const showToastSelection = async () => {
    setIdtoast(idtoast + 1);
    setToast({ showToast: false });
    setToast({ showToast: true, message: 'Please select at least one week to edit', type: 'info' });
  };

  const handleSubmit = async (values) => {
    // console.log('values', values);
    try {
      if (token) {        
        let valuesList = [];
        
        if(selectedOptions?.length > 1)//Multiple edition
        {          
          valuesList = selectedOptions.map(value => ({
            scheduleId: value.scheduleId,
            prodFamilyId: value.ProdFamilyId,
            buildWeek: formatBuildWeek(value.productionBuildWeek),
            offset: value.offset,
            capacity: values.capacity,
            notes: values.notes ? values.notes : '',
            reservedJobDes: values.reservedJobDesc ? values.reservedJobDesc : '',
            reservedHours: values.reservedHours,
            adjustedHours: values.adjustedHours,
            author: truncateEmail(accounts[0].username, 50),
            userRole: employeeRole,
          }));
        }        
        else //Single edition
        {
          const mappedValues = {
            scheduleId: values.scheduleId,
            prodFamilyId: values.ProdFamilyId,
            buildWeek: formatBuildWeek(values.productionBuildWeek),
            offset: values.offset,
            capacity: values.capacity,
            notes: values.notes ? values.notes : '',
            reservedJobDes: values.reservedJobDesc ? values.reservedJobDesc : '',
            reservedHours: values.reservedHours,
            adjustedHours: values.adjustedHours,
            author: truncateEmail(accounts[0].username, 50),
            userRole: employeeRole,
          };
  
          valuesList = [mappedValues];
        }
        
        //const enpointUrl = `/updateBuildWeeks/${encodeURIComponent(values.scheduleId)}?&plantProdFamilyId=${encodeURIComponent(values.plantProdFamilyId)}&buildWeek=${encodeURIComponent(values.productionBuildWeek)}&reservedHours=${encodeURIComponent(values.reservedHours)}&buildWeekCapacity=${encodeURIComponent(values.capacity)}&author=${encodeURIComponent(truncateEmail(accounts[0].username,50))}&offset=${encodeURIComponent(values.offset)}&adjustedHours=${encodeURIComponent(values.adjustedHours)}&notes=${encodeURIComponent(values.notes)}&reservedJobDes=${encodeURIComponent(values.reservedJobDesc)}`;    
        const response = await axios.put(
          `/updateBuildWeeks`,
          valuesList,
          {
            headers: {
              Authorization: token,
            },
          }
        );
                
        if (response.status === 200) {                
          setDrafthoursEdit(!drafthoursEdit);
        }      
      }
      
    } catch (error) {
      showToastError(error);
    };

    setOpenForm(false);
    setOpenMultipleForm(false);
  }
  // Edit drafting week form

  return (
    <div style={{ paddingLeft: '30px', paddingRight: '30px' }}>
    {toast && <Toast key={idtoast} showToast={toast.showToast} message={toast.message} type={toast.type} />}
      <TabStrip
        selected={selected}
        onSelect={handleSelect}
        style={{ border: 'none' }}
        className='main-c-shadow'
      >
        <TabStripTab title=''>
          <div style={{margin: '16px',  display: 'flex', justifyContent: 'space-between', flexDirection: 'row'}}>
            <div style={{
            justifyContent: "flex-start",
            display: "flex",
            marginTop: "12px",
            gap: "2px",
            flexDirection: "row",
            alignItems: "flex-end",
          }}>
            <Button 
              tag={Link}
              className='secondary-button roboto-15-500 blue-text' 
              to="/">
              <SvgIcon icon={chevronLeftIcon} size={'large'} color='#0097E0'></SvgIcon>
              Return
            </Button>
            </div>
            <div style={{
              justifyContent: "flex-end",
              flexDirection: "row",
              display: "flex",
              alignItems: "center",
              gap: "8px",
            }}>
            <Button
              onClick={handleEditSelected}
              className='primary-button roboto-15-500'> 
              Edit
              <svg width="13" height="13" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M15.3243 0.486487C15.3243 0.486487 16.4189 -0.608108 17.5135 0.486487C18.6081 1.58108 17.5135 2.67568 17.5135 2.67568L7.66211 12.527H5.47292V10.3378L15.3243 0.486487ZM2.18919 2.67567V15.8108H15.3243V9.24324L17.5135 7.05405V15.8108V18H15.3243H2.18919H0V15.8108V2.67567V0.486483H2.18919H10.9459L8.75676 2.67567H2.18919Z" fill="white"/>
              </svg>
            </Button>
            <button
              className="roboto-15-500 secondary-button blue-text"
              onClick={() => setExportExcel(true)}
            >
              {" "}
              Export
            </button>
          </div>
          </div>
          {/* Active filters chips section*/}
          {Object.keys(chipsData).length > 1 && ( 
            <div style={{ margin: '20px 15px 15px 15px', display: 'flex', flexDirection: 'row', gap: '10px',  justifyContent: 'space-between'}}> 
              <div style={{ display: 'flex', gap:'10px', alignItems: 'center'}}>
                <div className={"chipList k-form-field-wrap"} style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                  <ChipList
                    data={Object.keys(chipsData)}
                    selection="multiple"        
                    chip={(props) => (
                      <div style={{ display: 'flex', marginRight: '5px', marginTop: '10px' }} title={chipsData[props.dataItem].join(', ')}>
                        <Chip
                          className='ml-2 mb-2 customChipBackground'
                          size={size}
                          fillMode={fillMode}
                          rounded={rounded}
                          removable={(DraftHoursColumns.find(col => col.field === props.dataItem)) ? true : false}
                          disabled={(DraftHoursColumns.find(col => col.field === props.dataItem)) ? false : true}
                          onRemove={() => clearFilter(props.dataItem)}
                          text={
                            <div>
                              <span className='roboto-15-500' style={{display: (DraftHoursColumns.find(col => col.field === props.dataItem)) ? 'inline' : 'none'}}>{(DraftHoursColumns.find(col => col.field === props.dataItem)) ? (DraftHoursColumns.find(col => col.field === props.dataItem)).title : "filters"}</span>
                              <span style={{ color: '#CECECE', marginLeft: '5px', marginRight: '5px', display: (DraftHoursColumns.find(col => col.field === props.dataItem)) ? 'inline' : 'none' }}>|</span>          
                              <span className='roboto-15-400'>
                                {chipsData[props.dataItem].slice(0, 10).join(', ')}
                                {chipsData[props.dataItem].length > 10 && '...'}
                              </span>
                            </div>
                          }
                        />
                      </div>
                    )}
                  />
                </div>
              </div>
              <div>
                <button
                  className="roboto-15-500 secondary-button blue-text"
                  onClick={handleResetFilter}
                  style={{ width: '140px', border: 'none' }}
                >
                  {'Clear all filters'}<img className="settings-icon1 ml-5" alt="" src="/filter.svg" />
                </button>
              </div> 
            </div>
          )}
          <DraftHoursGrid 
            columns={DraftHoursColumns} 
            dateRange={dateRange} 
            filter={filter} 
            setFilter={setFilter}
            prevFilter={prevFilter}
            setPrevFilter={setPrevFilter}
            currentFilter={currentFilter}
            setCurrentFilter={setCurrentFilter}
            disabled={true}

            onRemoveAll={handleRemoveAllFilters}            
            removeFilter={removeFilter}
            setRemoveFilter={setRemoveFilter}
            setDropdownFilters={setDropdownFilters}
            dropdownFilters={dropdownFilters}

            exportExcel={exportExcel}
            setExportExcel={setExportExcel}

            selectedOptions = {selectedOptions}
            setSelectedOptions = {setSelectedOptions}
            onDrafthoursEdit={drafthoursEdit}
          />
        </TabStripTab>
      </TabStrip>

      { (openForm || openMultipleForm) && (
      <Dialog 
        onClose={handleCancelEdit}
        height={400}
        width={730}
        >
        <div style={{padding: '8px'}} className='roboto-15-400'>
          <div className='roboto-19-700' style={{ marginBottom: '16px', display: 'flex', flexDirection: 'row', gap: '16px' }}>
            <p style= {{display: openMultipleForm ? 'none' : 'flex'}}>{`Edit drafting week ${formatDate(editItem.productionBuildWeek, false, 1)} capacity`}</p>
            <p style= {{display: openMultipleForm ? 'flex' : 'none'}}>{`Edit drafting weeks capacity`}</p> 
            <Button
              onClick={handleCancelEdit}
              style={{
                  position: 'absolute', right: '24px',
                  width: '24px',
                  height: '24px',
                  borderRadius: '180px',
                  backgroundColor: '#F2F2F2',
                  border: 'none',
                  padding: 0,
              }}> <SvgIcon icon={xIcon} color='#8D8D8D'></SvgIcon>
            </Button> 
          </div>
          <Form
            initialValues={editItem}
            onSubmit={handleSubmit}
            render={(formRenderProps) => (
              <EditForm
                formRenderProps={formRenderProps}
                item={editItem}
                cancelEdit={handleCancelEdit}
                // disabled = {disabled}
              />
            )}
          />
        </div>
      </Dialog>
      )}

      <Modal
        visible={modalVisible}
        onClose={() => setModalVisible(false)}
        title='Multiple modification'
        message='The filled input texts will be override in the selected drafting weeks.
        Do you want to continue?'
        onAccept={handleMultipleEdit}
        type='INFORMATION'
      />

    </div>
  );
};

export default TabComponentDraftHours;
