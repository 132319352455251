import { ErrorIcon } from '../Icons/ErrorIcon';
import './ErrorsBanner.css'

const ErrorsBanner = ({ codeErrors }) => {
  return (
    <div className='fs-15 ErrorsBanner_card'>
      <div className='ErrorsBanner_iconContainer'>
        <ErrorIcon></ErrorIcon>
      </div>
      <div className='ErrorsBanner_messagesContainer'>
        {
          codeErrors.map((error, index) => (
            <div key={index}>
              {error}
            </div>
          ))}
      </div>
    </div>
  );
};

export default ErrorsBanner;
