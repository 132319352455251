import React, { useEffect, useState, useRef } from 'react';
import axios from '../axiosConfig';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { filterBy } from "@progress/kendo-data-query";
import { orderBy } from "@progress/kendo-data-query";
import '@progress/kendo-theme-default/dist/all.css';
import './GeneralGrid';
import CustomLockedCell from './customizedColumns/CustomLockedCell'
import SidePanel from './SidePanel';
import useProfileData from '../services/ProfileDataService';
import CustomDropdownCell from './customizedColumns/CustomDropdownCell';
import AssignButton from './customizedColumns/AssignButton';
import CustomFilter from '../components/customizedFilters/CustomFilter';
import Loader from './Loader';
import Toast from './ToastNotification';
import { truncateEmail } from '../Utilities/utils';
import { useMsal } from "@azure/msal-react";
import Modal from './Modal';
import instance from '../axiosConfig';
import LogOutService from '../services/LogOutService';
import { getCurrentLine,subscribeToCurrentLineChanges  } from '../services/CurrentLineService';
import useDebounce from "./../Utilities/useDebounce";
import {
  ExcelExport,
  ExcelExportColumn,
} from "@progress/kendo-react-excel-export";

const CancelledShippedGrid = ({ columns = [], dateRange,
  filter, setFilter,
  prevFilter, setPrevFilter,
  currentFilter, setCurrentFilter, onRemoveAll,
  setRemoveFilter, dropdownFilters, setDropdownFilters, removeFilter, disabled,
  exportExcel, setExportExcel
}) => {
  const [scheduleData, setScheduleData] = useState({ TotalRows: 0, Items: [] });
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);

  
  const storedCurrentPage = localStorage.getItem('currentPageCanShippView');
  const storedPage = localStorage.getItem('pageCanShippView');
  const storedStartDate = localStorage.getItem('startDate');
  const storedEndDate = localStorage.getItem('endDate');

  const [pageSize, setPageSize] = useState(storedPage ? JSON.parse(storedPage).take: 50);
  const [sort, setSort] = useState([{ field: "isPriority", dir: "desc" }]);


  const [page, setPage] = useState(
    { skip: storedPage ? JSON.parse(storedPage).skip : 0,
     take: storedPage ? JSON.parse(storedPage).take : pageSize 
    });

  const { token, profileImageData } = useProfileData();
  const [currentPage, setCurrentPage] = useState(storedCurrentPage ? JSON.parse(storedCurrentPage) : 1);
  const [totalRows, setTotalRows] = useState(0);
  const [department, setDepartment] = useState(getCurrentLine().ProdFamilyId);

  const [toast, setToast] = useState(null);
  const [idtoast, setIdtoast] = useState(1);
  const { instance } = useMsal();  
  const viewAlias = 1;
  const [productName, setProductName] = useState(getCurrentLine().ProdFamilyName); 
  const debouncedFilter = useDebounce(filter, "600");
  
   const _export = React.useRef(null);
    const _formattedDate = (() => {
      const today = new Date();
      const month = (today.getMonth() + 1).toString().padStart(2, "0");
      const day = today.getDate().toString().padStart(2, "0");
      const year = today.getFullYear();
      return `${month}-${day}-${year}`;
    })();
  
    const excelExport = () => {
      if (_export.current !== null && exportExcel === true) {
        const today = new Date();
        _export.current.save(users);
      }
    };
  
    // Columns export
    const exportColumns = columns.map((col, index) => (
      <ExcelExportColumn key={index} field={col.field} title={col.title} />
    ));
  
    useEffect(() => {
      excelExport();
      setExportExcel(false);
    }, [exportExcel === true]);

  const fetchData = async () => {

    try {
      setLoading(true);            
      if (token) {
        const response = await axios.post(
          `/Department/pagedFilter/${encodeURIComponent(sort?.[0]?.field || 'isPriority')}`,
          {
            prodFamilyId: department,
            dateColumn: 'DraftingDueDate',
            sort: sort?.[0]?.dir || 'des',
            skip: page.skip,
            take: page.take,
            startDate: dateRange.startDate || storedStartDate,
            endDate: dateRange.endDate || storedEndDate,
            filter: JSON.stringify(filter, null, 2),
            viewAlias: viewAlias,
            productName: productName,
          },
          {
            headers: {
              Authorization: token,
            },
          }
        );

        const usersWithIndex = response.data.items.map((user, index) => ({
          ...user,
          rowIndex: index + 1 + page.skip,
        }));

        setScheduleData({
          TotalRows: response.data.TotalRows,
          Items: usersWithIndex,
        });

        setUsers(usersWithIndex);
        setTotalRows(response.data.totalRows);
        setLoading(false);

      }
    } catch (error) {

      if (error.response) {

        setToast({ showToast: true, message: error.response.data, type: 'error' });
        if(error.response.status === 401) LogOutService(instance);       
      } else {
        setToast({ showToast: true, message:  error.message, type: 'error' });
      }
      setLoading(false);
    }
  };

  //Notice changes on current Line service
  useEffect(() => {
    const unsubscribe = subscribeToCurrentLineChanges(newCurrentLine => {
      setDepartment(newCurrentLine.ProdFamilyId);
      setProductName(newCurrentLine.ProdFamilyName);
    });
    return () => {
      unsubscribe();
    };
  }, []);  

  useEffect(() => {
    setToast({ showToast: false });
    setIdtoast(idtoast + 1);

    fetchData();
  }, [token, page.skip, page.take, sort, dateRange, debouncedFilter, department]);

  useEffect(() => {
    setPage({ skip: 0, take: pageSize });
    setCurrentPage(1);
  }, [dateRange, sort, debouncedFilter]);

  const [filtro, setFiltro] = useState(null)

  useEffect(() => {
    localStorage.setItem('filtersGeneralView', JSON.stringify(filter, null, 2));
    const sotredFilters = JSON.stringify(filter?.filters, null, 2);

    if(sotredFilters)
    localStorage.setItem('dropDownFiltersGeneralView', sotredFilters);    
  }, [filter]);


  const filterConfig = {
    text: "text",
    number: "numeric",
    boolean: "boolean",
    date: "date",
    dropdown: {
      filterCell: (props) => {
        const { field } = props;
        return (
          <CustomFilter
            {...props}
            data={users}
            field={field}
            currentFilterField={field}
            onSelectionChange={handleCustomFilterChange}
            onRemoveAll={onRemoveAll}
            selectedOptions2={currentFilter}
            selectedFilter={filtro}
            removeFilter={removeFilter}
            prevFilter={prevFilter}
            filter={dropdownFilters}
            viewOption={viewAlias}            
          />
        );
      }
    }
  };

  const handlePriorityUpdate = async (unmarkSuccess) => {
    if (unmarkSuccess == true) {
      setIdtoast(idtoast + 1);
      setToast({ showToast: false });
      setToast({ showToast: true, message: 'Order unmarked successfully', type: 'success' });
    }
    await fetchData();
  };

  const handleAssignOrder = async () => {

    setIdtoast(idtoast + 1);
    setToast({ showToast: false });
    setToast({ showToast: true, message: 'Order assigned successfully', type: 'success' });

    await fetchData();
  };

  const handleUnassignOrder = async () => {

    setIdtoast(idtoast + 1);
    setToast({ showToast: false });
    setToast({ showToast: true, message: 'Order unassigned successfully', type: 'success' });

    await fetchData();
  };

  const handleAssignError = async () => {

    setIdtoast(idtoast + 1);
    setToast({ showToast: false });
    setToast({ showToast: true, message: 'An error occurred while assigning the order', type: 'error' });
  };
  
  const handleCustomFilterChange = (selectedOptions, currentFilterField) => {
    
    setFilter((prevFilter) => {
      if (!prevFilter) {
        // if there are not a existing filter creatte a new one
        return {
          logic: "or",
          filters: [
            ...selectedOptions.map((option) => ({
              field: currentFilterField,
              operator: "eq",
              value: option,
            })),
          ],
        };
      } else {
        // if there already exist a filter add current selection
        const updatedFilters = [
          ...prevFilter.filters.filter((filter) => filter.field !== currentFilterField),
          ...selectedOptions.map((option) => ({
            field: currentFilterField,
            operator: "eq",
            value: option,
          })),
        ];

        return {
          ...prevFilter,
          filters: updatedFilters,
        };
      }
    });
    setFiltro(currentFilterField);
    setCurrentFilter(selectedOptions);
    localStorage.setItem('currentFilterGeneralView', JSON.stringify(selectedOptions));
    setRemoveFilter(false);
    const allFields = filter?.filters?.map(filter => filter.field) || [];
    allFields.unshift(currentFilterField);
    const allFields2 = [...new Set(allFields)];
    setPrevFilter(allFields2);
    localStorage.setItem('currentPrevFilterGeneralView', JSON.stringify(allFields2));
    setDropdownFilters(filter?.filters);
  };


  const pageChange = (event) => {
    const targetEvent = event.targetEvent;
    const newPage = {
      skip: event.page.skip,
      take: targetEvent.value === 'All' ? totalRows : event.page.take,
    };

    setPage(newPage);
    setCurrentPage(newPage.skip / pageSize + 1);

    localStorage.setItem('pageCanShippView', JSON.stringify(newPage));
    localStorage.setItem('currentPageCanShippView', JSON.stringify(newPage.skip / pageSize + 1));
  };

  const handleFilterChange = (event) => {
    setFilter(event.filter);
  };

  const filteredUsers = filterBy(users, filter);
  const sortedUsers = orderBy(filteredUsers, sort);

  const [selectedColumns, setSelectedColumns] = useState(columns);

  useEffect(() => {
    setSelectedColumns(columns);
  }, [columns], [sortedUsers]);

  const [selectedColumnFields, setSelectedColumnFields] = useState(columns.map(column => column.field));

  useEffect(() => {
    setSelectedColumnFields(columns.map(column => column.field));
  }, [columns]);

  const [openForm, setOpenForm] = React.useState(false);
  const [editItem, setEditItem] = React.useState();
  const [data, setData] = React.useState(sortedUsers);

  const enterEdit = async (item) => {
    
    const updatedItem = {
      ...item,
      orderNumber: 0,
      orderNumberChecker: 0,
    };

    setOpenForm(true);
    setEditItem(updatedItem);

};

  const handleDesignerDetailHrsChange = (newDesignerDetailHrs,) => {
    setEditItem((prevItem) => ({
      ...prevItem,
      orderNumber: newDesignerDetailHrs      
    }));
  };

  const handleDesignerCheckerHrsChange = (newDesignerCheckerHrs) => {
    setEditItem((prevItem) => ({
      ...prevItem,
      orderNumberChecker: newDesignerCheckerHrs
    }));
  };

  useEffect(() => {
    setPageSize(page.take);
  }, [page]);


  const handleSubmit = (event) => {
    let newData = data.map((item) => {
      if (event.item === item.item) {
        item = {
          ...event,
        };
      }
      return item;
    });
    setData(newData);



    setOpenForm(false);
  };

  const [profileImgAssigned, setProfileImgAssigned] = useState();
  const [designerName, setName] = useState();
  const [modalVisible, setModalVisible] = useState(false);

  const downloadFile = async (imageName) => {
    setIdtoast(idtoast + 1);
    setToast({ showToast: false });
    let errorMessage;
    if (token) {
      const downloadUrl = `/download/blob?&fileName=${encodeURIComponent(imageName)}&departmentsFolder=${encodeURIComponent("")}&departmentFolder=${encodeURIComponent("")}&orderNumFolder=${""}&containerName=${encodeURIComponent("employeeprofileimages")}&isProfileImage=${encodeURIComponent(true)}`;
      try {
        const response = await axios.get(downloadUrl, {
          headers: {
            Authorization: token,
          },
          responseType: 'blob',
        });

      const blob = new Blob([response.data], { type: response.headers['content-type'] });
      const imageUrl = URL.createObjectURL(blob);

      const imageSrc = imageUrl;

      if (imageSrc == null) {
        setProfileImgAssigned('/DefaultProfile.png');
      }
      else {
        setProfileImgAssigned(imageSrc);
      }

      } catch (error) {
        if (error.response) {

          const status = error.response.status;
          const errorMessages = {
            401: 'Expired Token',
            500: 'The file does not exist on the blob storage'
          };

          errorMessage = errorMessages[status] || 'An error occurred';
        } else {
          errorMessage = error.message || 'An unexpected error occurred';
        }

        setToast({ showToast: true, message: errorMessage, type: 'error' });
      }
    }
  };


  const handleCancelEdit = () => {
    setOpenForm(false);
  };

  const MyCustomLockedCell = (props) => (
    <CustomLockedCell {...props} enterEdit={enterEdit} />
  );

  const handleSortChange = (e) => {
    setSort(e.sort || []);
  };

  const handleModalError = async (image, userName) => {
    downloadFile(image);
    setName(userName);
    setModalVisible(true);

    await fetchData();
  };

  // Zoom adjustment
  const [zoomLevel, setZoomLevel] = useState(1);
  const [gridHeight, setGridHeight] = useState(window.innerHeight - 270);

  useEffect(() => {
    const handleResize = () => {
      const viewportWidth = window.innerWidth;
      const windowWidth = window.outerWidth;
      const newZoomLevel = windowWidth / viewportWidth;
      setZoomLevel(newZoomLevel);

      const newGridHeight = window.innerHeight - 270;
      setGridHeight(newGridHeight);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);
  // Zoom adjustment

  return (
    <div style={{ position: 'relative' }}>  
      {loading && (
        <Loader></Loader>
      )}

      <Modal
        visible={modalVisible}
        onClose={() => setModalVisible(false)}
        title='Oops! It looks like this task was already taken'
        message={designerName + ' took this task'}
        type='TAKENORDER'
        image= {profileImgAssigned}
      />

      {toast && <Toast key={idtoast} showToast={toast.showToast} message={toast.message} type={toast.type} />}

      <div style={{ height: gridHeight, marginBottom: '15px', position: 'relative' }}>
        <ExcelExport
                  data={users || []}
                  ref={_export}
                  fileName={`cancelled_shipped_${_formattedDate}.xlsx`}
                >
                  {exportColumns}
                </ExcelExport>
        <Grid
          className='roboto-15-400'
          data={(users || [])}
          skip={page.skip}
          take={page.take}
          total={totalRows}
          pageable={{
            buttonCount: 4,
            type: "input",
            pageSizes: [50, 150, 200, totalRows].filter(num => num <= totalRows),
            pageSize: pageSize,
          }}
          onPageChange={pageChange}
          sortable={true}
          filterable={true}
          filter={filter}
          onFilterChange={handleFilterChange}
          resizable
          reorderable
          sort={sort}
          onSortChange={handleSortChange}
          style={{
            borderRadius: '8px',
            display: 'flex',
            margin: '15px',
            height: 'inherit'
          }}
        >
          <GridColumn
            className='roboto-15-400'
            reorderable={false}
            sortable={false}
            filterable={false}
            editable={false}
            field="rowIndex"
            title="#"
            width="40px"
          />
          {selectedColumns
            .filter((column) => column.visible)
            .map((column) => {
              if (column.field === 'esoistatusDesc') {
                return (
                  <GridColumn
                    className='roboto-15-400'
                    key={column.field}
                    field={column.field}
                    title={column.title}
                    width={column.minWidth}
                    filter={filterConfig[column.filterType]}
                    filterCell={filterConfig[column.filterType].filterCell}
                    cell={(props) => (
                      <CustomDropdownCell
                        {...props}
                        updateData={(updatedData) => handleSubmit({ userId: props.dataItem.userId, ...updatedData })}
                        disabled={true}
                        employeeRole={0}
                      />
                    )}
                  />
                );
              } else if (column.field === 'designerChecker' || column.field === 'designerDetail') {
                return (
                  <GridColumn
                    className='roboto-15-400'
                    key={column.field}
                    field={column.field}
                    title={column.title}
                    width={column.minWidth}
                    filter={filterConfig[column.filterType]}
                    filterCell={filterConfig[column.filterType].filterCell}
                    cell={(props) => {
                      if ((props.dataItem.itemType === 'FIN' && column.field === 'designerDetail') || (props.dataItem.itemType !== 'FIN')) { 
                        return (
                          <AssignButton
                            {...props}
                            updateData={(updatedData) => handleSubmit({ userId: props.dataItem.userId, ...updatedData })}
                            token={token}
                            onAssignOrder={handleAssignOrder}
                            onUnassignOrder={handleUnassignOrder}
                            onErrorAssign={handleAssignError}
                            modalError={handleModalError}
                            disabled={true}
                            employeeRole={0}
                          />
                        );
                      } else { //do not show button if it is standard and checker
                        return <div style={{ width: '100%', height: '100%' }}></div>;
                      }
                    }}
                  />
                );

              }              
              else {
                if (column.field === 'etoproc') 
                return(<GridColumn
                  className='roboto-15-400'
                  sortable={true}
                  key={column.field}
                  field={column.field}
                  title={column.title}
                  headerCell={column.header}
                  width={column.minWidth}
                  filter={filterConfig[column.filterType]}
                  format="{0:d}"
                  filterCell={filterConfig[column.filterType].filterCell}
                  cell={(props) => {
                    if ((props.dataItem.itemType !== 'FIN' && column.field === 'etoproc') ) { 
                      return (
                        column.cell(props)
                      );
                    } else { 
                      return <div style={{ width: '100%', height: '100%' }}></div>;
                    }
                  }}
                />)
                else if (column.field === 'sellingPrice') {
                return (
                  <GridColumn
                  className='roboto-15-400'
                  sortable={true}
                  key={column.field}
                  field={column.field}
                  title={column.title}
                  headerCell={column.header}
                  width={column.minWidth}
                  filter="numeric"  
                  format="{0:n2}"
                  filterCell={filterConfig[column.filterType].filterCell}
                  cell={column.cell}
                />
                );
              }          
              else if (column.field === 'phase') {
                return (
                  <GridColumn
                    className='roboto-15-400'
                    sortable={false}
                    key={column.field}
                    field={column.field}
                    title={column.title}
                    headerCell={column.header}
                    width={column.minWidth}
                    filter={filterConfig[column.filterType]}
                    filterable={false}
                    filterCell={filterConfig[column.filterType].filterCell}
                    cell={column.cell}
                  />
                  );
              }            
              else
              return (
                <GridColumn
                  className='roboto-15-400'
                  sortable={true}
                  key={column.field}
                  field={column.field}
                  title={column.title}
                  headerCell={column.header}
                  width={column.minWidth}
                  filter={filterConfig[column.filterType]}
                  format="{0:d}"
                  filterCell={filterConfig[column.filterType].filterCell}
                  cell={column.cell}
                />
              );
              }
            })}

          <GridColumn
            filterable={false}
            editable={false}
            width="40px"
            locked={true}
            cell={MyCustomLockedCell}
          />
        </Grid>
      </div>

      {openForm && editItem && editItem.orderDetailId && (
        <SidePanel
          cancelEdit={handleCancelEdit}          
          item={editItem}
          onPriorityUpdate={handlePriorityUpdate}
          onDesignerCheckerHrsChange={handleDesignerCheckerHrsChange}
          onDesignerDetailHrsChange={handleDesignerDetailHrsChange}
          disabled= {true}
        />
      )}
    </div>
  );
}

export default CancelledShippedGrid;