import React, { useState, useEffect, useRef } from 'react';
import { Popup } from '@progress/kendo-react-popup';
import { InputPrefix, TextBox } from "@progress/kendo-react-inputs";
import '@progress/kendo-theme-default/dist/all.css';
import './CustomFilter.css';
import { SvgIcon } from "@progress/kendo-react-common";
import { searchIcon } from "@progress/kendo-svg-icons";
import axios from '../../axiosConfig';
import useProfileData from '../../services/ProfileDataService';
import { getCurrentLine} from '../../services/CurrentLineService';

const CustomFilter = ({ data, field, onSelectionChange,
  currentFilterField, onRemoveAll, selectedOptions2,
  selectedFilter, removeFilter, prevFilter, filter, viewOption, endPointData}, props) => {

  const [searchText, setSearchText] = useState('');
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [show, setShow] = useState(false);
  const [columnOptions, setColumnOptions] = useState(viewOption === 4 ? endPointData : []);
  const [prevFilteredOptions, setPrevFilteredOptions] = useState([]);
  const anchor = useRef(null);

  const { token } = useProfileData(false);
  
  // **************Dynamic filters approach**************
  // const customData = data.map((item, index) => ({
  //   key: index,
  //   field: item[field], 
  // }));

  // function onlyUnique(value, index, self) {
  //   return self.findIndex((item) => item.field === value.field) === index;
  // }
  // const allOptions = customData.filter(onlyUnique);

  // **************Static filters approach**************
  const fieldValues = (columnOptions?.find(column => column.columnName === field)?.uniqueValues) ?? [];
  const updatedEndPoints = fieldValues.map((value, index) => ({
    key: index, 
    field: value 
  }));
  const allOptions = updatedEndPoints;

  const handleFilter = () => {
    removeFilter = null;

    const filteredOptions = allOptions.filter(option =>
      (typeof option.field === 'string' || typeof option.field === 'number') &&
      option.field.toString().toLowerCase().includes(searchText.toLowerCase())
    );

    const selectedOptionsFromFilter = filteredOptions.map(option => option.field);

    // put together previous and next selections
    const newSelectedOptions = [...selectedOptions, ...selectedOptionsFromFilter];
    setSelectedOptions(newSelectedOptions);

    if (onSelectionChange) {
      onSelectionChange(selectedOptions, currentFilterField);
    }
  };

  const fetchFiltersData = async () => {
    const prductName= getCurrentLine().ProdFamilyName;
    const ProdFamilyId= getCurrentLine().ProdFamilyId;

    if (token) {
        const response = await axios.get(
            `/Department/GetFilterOptions/${encodeURIComponent(ProdFamilyId)}?plantProdFamilyId=${encodeURIComponent(getCurrentLine().PlantProdFamilyId)}&columnNameDistinct=${encodeURIComponent(field)}&ViewOption=${encodeURIComponent(viewOption)}&productName=${encodeURIComponent(prductName)}`,            
            {
                headers: {
                    Authorization: token,
                },
            }
        );

        setColumnOptions(response.data);               
    }
  };    

  const onClick = () => {
    setShow(!show);
        
    //Fill filters options only when drop down opens and is not reference order view
    if(!show && (viewOption !== 4))
      fetchFiltersData();
  };

  const handleSearch = (event) => {
    setSearchText(event.target.value);
  };

  const [removeFilter2, setRemoveFilter2] = useState(false)

  const removeAllOptions = () => {
    setCheckboxState({});
    setSelectedOptions([]);
    setRemoveFilter2(true);

    if (onRemoveAll) {
      onRemoveAll(currentFilterField);
      onRemoveAll(currentFilterField);
    }
  };

  const filteredOptions = allOptions.filter(option =>
    (typeof option.field === 'string' || typeof option.field === 'number') &&
    option.field.toString().toLowerCase().includes(searchText.toLowerCase())
  );

  let processedFields = {};
  let fieldCounts = {};

  const selectedText =
    selectedOptions.length > 0
      ? selectedOptions.join(', ').substring(0, 40) +
        (selectedOptions.length > 1 ? '' : '') //'...' : ''
      : (
        <><p style={{overflow: 'hidden', color: '#BFBFBF'}}>Select...</p>
        <div><svg
          transform={show ? 'rotate(-180)' : ''}
          xmlns="http://www.w3.org/2000/svg"
          width="9"
          height="6"
          viewBox="0 0 9 6"
          fill="#1C2325"
        >
          <path d="M4.04109 5.355L0.444093 1.2435C0.0195932 0.75975 0.364593 2.75779e-07 1.00884 2.75779e-07H8.20284C8.34703 -0.000123345 8.48819 0.0413159 8.60943 0.119356C8.73067 0.197396 8.82684 0.308728 8.88643 0.44002C8.94602 0.571312 8.96651 0.716999 8.94543 0.859633C8.92435 1.00227 8.86261 1.1358 8.76759 1.24425L5.17059 5.35425C5.1002 5.43481 5.01338 5.49937 4.91598 5.54361C4.81857 5.58785 4.71283 5.61074 4.60584 5.61074C4.49886 5.61074 4.39312 5.58785 4.29571 5.54361C4.19831 5.49937 4.11149 5.43481 4.04109 5.35425V5.355Z" fill="#1C2325" />
        </svg></div> </>
      );

  if (Array.isArray(filter)) {
    fieldCounts = filter.reduce((counts, { field }) => {
      counts[field] = (counts[field] || 0) + 1;
      return counts;
    }, {});

    processedFields = filter.reduce((processed, { field, value }) => {
      processed[field] = [...(processed[field] || []), value];
      return processed;
    }, {});

    Object.keys(processedFields).forEach(field => {
      processedFields[field] = processedFields[field].join(', ').substring(0, 40);
    });
  }

  const displayTag = ((field != selectedFilter) || removeFilter);

  const selectedTextFiltered = selectedOptions2.join(', ').substring(0, 40);

  const formattedOptions = {};

  allOptions.forEach((option) => {
    const { field, value } = option;
    const formattedValue = (formattedOptions[field] || []).concat(value).join(', ').substring(0, 40);
    formattedOptions[field] = formattedValue;
  });

  // const optionsAsArray = Object.entries(formattedOptions).map(([field, value]) => `${field}${value}`);
  // const formattedString = optionsAsArray.join(', ').substring(0, 40);
  const prevFilterText = processedFields[field];
  const prevFilterCount = fieldCounts[field];
  
  // **************Keep options selected on the PREVIOUS SELECTION**************
  const filteredBooleanObject = filter
  ? filter.reduce((acc, curr) => {
      if (curr.field === currentFilterField) {
        acc[curr.value] = true;
      }
      return acc;
    }, {})
  : {};

  const [checkboxState, setCheckboxState] = useState(filteredBooleanObject);

  const [FilterText, setFilterText] = useState(prevFilterText); // Estado para el texto de filtro
  const [FilterCount, setFilterCount] = useState(prevFilterCount); // Estado para el contador de filtro

  const handleCheckboxChange = (field) => {
    const newState = { ...checkboxState, [field]: !checkboxState[field] };
    setCheckboxState(newState);
  
    const selectedOptions = Object.keys(newState).filter((key) => newState[key]);
    setSelectedOptions(selectedOptions);
    setPrevFilteredOptions(selectedOptions);
  
    // Reevaluar el objeto filtrado con las nuevas selecciones
    const updatedFilteredObject = {
      ...filteredBooleanObject,
      ...selectedOptions.reduce((acc, option) => {
        if (!filteredBooleanObject[option]) {
          acc[option] = true;
        }
        return acc;
      }, {})
    };
  
    // Calcula el texto de filtro basado en las selecciones actuales
    const selectedValues = selectedOptions.filter((option) => updatedFilteredObject[option]);
    const optionsAsArray = selectedValues.map((value) => `${value}`).join(', ').substring(0, 40);
  
    // Actualiza el texto del filtro y el objeto filtrado
    setFilterText(optionsAsArray);
    setFilterCount(selectedValues.length);
  };
  // **************Keep options selected on the PREVIOUS SELECTION**************

  // **************Keep options selected on the CURRENT SELECTION**************
  const currentFilter = selectedOptions2
    ? selectedOptions2.reduce((acc, option) => ({ ...acc, [option]: true }), {})
    : {};
    
    const [checkboxStateCurrent, setCheckboxStateCurrent] = useState(currentFilter);

    const [currentFilterText, setCurrentFilterText] = useState(selectedTextFiltered); // Estado para el texto de filtro
    const [currentFilterCount, setCurrentFilterCount] = useState(selectedOptions2.length); // Estado para el contador de filtro  


  const handleCheckboxChangeCurrent = (field) => {
    const newState = { ...checkboxStateCurrent, [field]: !checkboxStateCurrent[field] };
    setCheckboxStateCurrent(newState);
  
    const selectedOptions = Object.keys(newState).filter((key) => newState[key]);
    setSelectedOptions(selectedOptions);
    setPrevFilteredOptions(selectedOptions);
  
    // Reevaluar el objeto filtrado con las nuevas selecciones
    const updatedFilteredObject = {
      ...filteredBooleanObject,
      ...selectedOptions.reduce((acc, option) => {
        if (!filteredBooleanObject[option]) {
          acc[option] = true;
        }
        return acc;
      }, {})
    };
  
    // Calcula el texto de filtro basado en las selecciones actuales
    const selectedValues = selectedOptions.filter((option) => updatedFilteredObject[option]);
    const optionsAsArray = selectedValues.map((value) => `${value}`).join(', ').substring(0, 40);
  
    // Actualiza el texto del filtro y el objeto filtrado
    setCurrentFilterText(optionsAsArray);
    setCurrentFilterCount(selectedValues.length);
  };
  // **************Keep options selected on the CURRENT SELECTION**************

    // **************Keep options selected on the NOT SELECTED**************   
    const [checkboxStateNOT, setCheckboxStateNOT] = useState({});

    const handleCheckboxChangeNOT = (field) => {
      const newState = { ...checkboxStateNOT, [field]: !checkboxStateNOT[field] };
      setCheckboxStateNOT(newState);
  
      const selectedOptions = Object.keys(newState).filter((key) => newState[key]);
      setSelectedOptions(selectedOptions);
      setPrevFilteredOptions(selectedOptions);
  };
  // **************Keep options selected on the NOT SELECTED**************

  const handleClear = () => {
    setCheckboxState({});
    setFilterText(null);  //Previous selection
    setFilterCount(null);  //Previous selection
    setSelectedOptions([]);
  
    if (onRemoveAll) {
      onRemoveAll(currentFilterField);
      setTimeout(() => {
        onRemoveAll(currentFilterField);
      }, 0);
    }
  };
  
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (show && anchor.current && !anchor.current.contains(event.target) && !event.target.closest('.popup-content')) {
        setShow(false);
        setCheckboxState(filteredBooleanObject);
        setFilterText(prevFilterText);  //Previous selection
        setFilterCount(prevFilterCount);  //Previous selection
      }
    };

    document.body.addEventListener('click', handleOutsideClick);

    return () => {
      document.body.removeEventListener('click', handleOutsideClick);
    };
  }, [show]);

  useEffect(() => {
    setShow(false);
    setCheckboxState(filteredBooleanObject); 
    setFilterText(prevFilterText);  //Previous selection
    setFilterCount(prevFilterCount);  //Previous selection
  }, []);

  const cleanedFilterText = FilterText?.trim().replace(/,$/, '');

  return (
    <div className='roboto-15-400'>
      <div
       className='input'
        style={{
          width: '100%',
          cursor: 'pointer',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          position: 'relative',
          padding: '0px 0px 0px 8px',
        }}
        onClick={onClick}
        ref={anchor}
      >
        {/* NOT SELECTED */}
        <div style={{display: (displayTag && !(prevFilter.includes(field)) || (selectedOptions2.length < 1 && !displayTag))
          ? 'flex': 'none',
          flexDirection: 'row', gap:'10px', alignItems: 'center', width: '100%'}}>
        <p className= 'input'
           style={{ overflow: 'hidden', border: 'none', padding: '0px', width: '100%'}}>
            {selectedText}
        </p>
        <div style={{ display: 'flex', flexDirection: 'row', gap:'10px', alignItems: 'center' }}>
        {selectedOptions.length > 0 && (
          <span
            style={{
              backgroundColor: 'var(--daikin-primary-daikin-blue)',
              justifyContent: 'center',
              padding: '0px 8px',
              borderRadius: '20px',
              color: 'white',
            }}
            title={selectedOptions.length}
          >
            {selectedOptions.length}
          </span>
        )}
        {selectedOptions.length > 0 && (
          <div
            style={{
              cursor: 'pointer',
              fontSize: '12px',
              marginRight: '8px',
              marginTop: '2px',
            }}
            onClick={removeAllOptions}
          >
            {/* x icon */}
            &#10006;
          </div>
        )}
        </div>
        </div>

        {/* NOT SELECTED */}
        {/* CURRENT SELECTION */}
        <div style={{ display: displayTag || (selectedOptions2.length < 1)? 'none': 'flex',
        flexDirection: 'row', gap:'10px', alignItems: 'center', width: '100%'}}>
        {selectedOptions2.length > 0 && (
        <p className= 'input'
           style={{ overflow: 'hidden', border: 'none', padding: '0px', width: '100%'}}>
            {currentFilterText}
        </p> )}
        <div style={{ display: 'flex', flexDirection: 'row', gap:'10px', alignItems: 'center' }}>
        {selectedOptions2.length > 0 && (
          <span
            style={{
              backgroundColor: 'var(--daikin-primary-daikin-blue)',
              justifyContent: 'center',
              padding: '0px 8px',
              borderRadius: '20px',
              color: 'white',
            }}
            title={currentFilterCount}
          >
            {currentFilterCount}
          </span>
        )}
        {selectedOptions2.length > 0 && (
          <div
            style={{
              cursor: 'pointer',
              fontSize: '12px',
              marginRight: '8px',
              marginTop: '2px',
            }}
            onClick={removeAllOptions}
          >
            {/* x icon */}
            &#10006;
          </div>
        )}
        </div>
        </div>
        {/* CURRENT SELECTION */}
        {/* PREVIOUS SELECTION */}
        <div style={{display: prevFilter.includes(field) && displayTag ? 'flex': 'none',
        flexDirection: 'row', gap:'10px', alignItems: 'center', width: '100%'}}>
        <p className= 'input'
           style={{display: 'flex', overflow: 'hidden', border: 'none', padding: '0px', width: '100%'}}>
            {cleanedFilterText}
        </p>
        <div style={{ display: 'flex', flexDirection: 'row', gap:'10px', alignItems: 'center' }}>
        {fieldCounts[field] > 0 && (
          <span
            style={{
              backgroundColor: 'var(--daikin-primary-daikin-blue)',
              justifyContent: 'center',
              padding: '0px 8px',
              borderRadius: '20px',
              color: 'white',
            }}
            title={fieldCounts[field]}
          >
            {FilterCount}
          </span>
        )}
        {fieldCounts[field] > 0 && (
          <div
            style={{
              cursor: 'pointer',
              fontSize: '12px',
              marginRight: '8px',
              marginTop: '2px',
            }}
            onClick={removeAllOptions}
          >
            {/* x icon */}
            &#10006;
          </div>
        )}
        </div>
        </div>
        {/* PREVIOUS SELECTION */}
      </div>
      <Popup
        anchor={anchor.current}
        anchorAlign={{
          horizontal: 'right',
          vertical: 'bottom',
        }}
        popupAlign={{
          horizontal: 'right',
          vertical: 'top',
        }}
        show={show}
        popupClass={'popup-content'}
        closeOnClick={true}
        style={{
          width: '200px',
        }}
      >
        <div style={{ height: '300px', overflowY: 'auto', overflowX: 'clip' }}>
          <TextBox
            className= 'roboto-15-400'
            value={searchText}
            onChange={handleSearch}
            // placeholder="Search..."
            style={{ margin: '10px', width: '90%' }}
            prefix={() => (
              <InputPrefix>
                <SvgIcon icon={searchIcon} />
              </InputPrefix>
            )}
          />
          {filteredOptions.map((option) => (
            <div
              className="roboto-15-400 dropdownHover"
              key={option.field}
              style={{
                padding: '8px 10px',
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'row',
                alignContent: 'baseline',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                // cursor: 'pointer'
              }
            }
              title= {option.field}
            >
              <div
                key={option.key}
                style={{
                  flex: '1',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  marginRight: '10px',
                }}
              >
                {option.field}
              </div>
                {/* NOT SELECTED */}
                <label className="custom-checkbox"
                style={{ 
                  display: (displayTag && !(prevFilter.includes(field)) || (selectedOptions2.length < 1 && !displayTag)) ? 'flex': 'none',
                  // background: 'green'
                }}>
                <input
                  type="checkbox"
                  checked={checkboxStateNOT[option.field]}
                  onChange={() => handleCheckboxChangeNOT(option.field)}
                  style={{ display: 'none' }}
                />
                <div
                  className={`checkbox-icon ${
                    checkboxStateNOT[option.field] ? 'checked' : ''
                  }`}
                  style={{ flex: 'none' }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="24"
                    viewBox="0 -960 960 960"
                    width="24"
                    fill="white"
                  >
                    <path d="M382-240 154-468l57-57 171 171 367-367 57 57-424 424Z" />
                  </svg>
                </div>
              </label> 
              {/* NOT SELECTED */} 
              {/* CURRENT SELECTION */}
              <label className="custom-checkbox"
                style={{ 
                  display: displayTag || (selectedOptions2.length < 1) ? 'none': 'flex', 
                  // background: 'red'
                }}>
                <input
                  type="checkbox"
                  checked={checkboxStateCurrent[option.field]}
                  onChange={() => handleCheckboxChangeCurrent(option.field)}
                  style={{ display: 'none' }}
                />
                <div
                  className={`checkbox-icon ${
                    checkboxStateCurrent[option.field] ? 'checked' : ''
                  }`}
                  style={{ flex: 'none' }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="24"
                    viewBox="0 -960 960 960"
                    width="24"
                    fill="white"
                  >
                    <path d="M382-240 154-468l57-57 171 171 367-367 57 57-424 424Z" />
                  </svg>
                </div>
              </label>
              {/* CURRENT SELECTION */}
              {/* PREVIOUS SELECTION */}
              <label className="custom-checkbox"
                style={{ 
                  display: prevFilter.includes(field) && displayTag ? 'flex': 'none',
                  // background: 'blue'
                }}>
                <input
                  type="checkbox"
                  checked={checkboxState[option.field]}
                  onChange={() => handleCheckboxChange(option.field)}
                  style={{ display: 'none' }}
                />
                <div
                  className={`checkbox-icon ${
                    checkboxState[option.field] ? 'checked' : ''
                  }`}
                  style={{ flex: 'none' }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="24"
                    viewBox="0 -960 960 960"
                    width="24"
                    fill="white"
                  >
                    <path d="M382-240 154-468l57-57 171 171 367-367 57 57-424 424Z" />
                  </svg>
                </div>
              </label>
              {/* PREVIOUS SELECTION */}              
            </div>
          ))}
          <div className="d-flex filter-options ">
            <button onClick={handleClear} className='secondary-button roboto-15-500 mx-1 bt' style={{ color: 'var(--daikin-primary-daikin-blue)', minWidth: '80px'}}>Clear</button>
            <button onClick={handleFilter} className='primary-button roboto-15-500 mx-1 bt' style={{ minWidth: '80px'}}>Filter</button>
          </div>
        </div>
      </Popup>
    </div>
  );
};

export default CustomFilter;