import React from 'react';
 
const ESOINumCell = (props) => {
  const { dataItem } = props;
 
  let valueToDisplay;
 
  if (dataItem.item) {
    valueToDisplay = dataItem.item;
  } else {
    valueToDisplay = dataItem.esoi;
  }
 
  if (dataItem.itemType === 'SPC') {
    // Special Orders (purple)
    return (
      <td
        className='roboto-15-400'
        style={{
          backgroundColor: "rgba(205, 184, 235, 0.5)",
        }}
      >
        {valueToDisplay}
      </td>
    );
  } else if (dataItem.itemType === 'FIN') {
    // Standard orders (blue)
    return (
      <td
        className='roboto-15-400'
        style={{
          backgroundColor: "rgba(209, 243, 255, 0.5)",
        }}
      >
        {valueToDisplay}
      </td>
    );
  } else {
    // KITS orders (green)
    return (
      <td
        className='roboto-15-400'
        style={{
          backgroundColor: "var(--color-green-20)",
        }}
      >
        {valueToDisplay}
      </td>
    );
  }
 
  // Default
  return <td className='roboto-15-400'>{valueToDisplay}</td>;
};
 
export default ESOINumCell;
  