import * as React from 'react';
import { SvgIcon } from "@progress/kendo-react-common";
import { circleIcon } from "@progress/kendo-svg-icons";
import Tooltip from '@mui/material/Tooltip';

const ESOIHeader = (props) => { 
  const { dataItem, field } = props;  
  const isNote = field === 'engnoteComp';
  const isCO = field === 'mgmt';
  const isStandard = !isNote && !isCO;

  const renderTooltipContent = () => {
    if (isNote) {
      return (
        <>
          <SvgIcon icon={circleIcon} size={'xlarge'} color="rgba(255, 73, 73, 1)" />{'Not uploaded'}
          <SvgIcon icon={circleIcon} size={'xlarge'} color="rgba(19, 206, 102, 1)" />{'Uploaded'}
          <SvgIcon icon={circleIcon} size={'xlarge'} color="rgba(220, 220, 220, 1)" />{'Not required'}
        </>
      );
    } else if (isCO) {
      return (
        <>
          {'Click on the icon to see the change order details'}
        </>
      );
    } else if (isStandard) {
      return (
        <>
          <SvgIcon icon={circleIcon} size={'xlarge'} color="rgba(209, 243, 255, 1)" />{'Standard'}
          <SvgIcon icon={circleIcon} size={'xlarge'} color="rgba(205, 184, 235, 1)" />{'Special'}
          <SvgIcon icon={circleIcon} size={'xlarge'} color="var(--color-green-20)" />{'KIT'}
        </>
      );
    }
  };

  return (
    <a className="k-link bg-white" onClick={props.onClick} style={{ flexDirection: 'row', display: 'flex', alignItems:'center', border: 'none' }}>
      <span className='k-grid-table-th k-link k-cell-inner k-column-title' style={{ gap: '8px', alignItems:'center' }}>
        <div>{props.title}</div>
        <Tooltip title={
          <div className='roboto-15-400 text-light' style={{ alignItems: 'center', marginRight: '8px' }}>
            {renderTooltipContent()}
          </div>
        } placement="top">
          <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none" style={{ minWidth: '21px', minHeight:'21px' }}>
            <path d="M10.606 1C5.63796 1 1.60596 5.032 1.60596 10C1.60596 14.968 5.63796 19 10.606 19C15.574 19 19.606 14.968 19.606 10C19.606 5.032 15.574 1 10.606 1ZM11.506 14.5H9.70596V9.1H11.506V14.5ZM11.506 7.3H9.70596V5.5H11.506V7.3Z" fill="#0097E0"/>
          </svg>
        </Tooltip>
      </span>
      <span className="k-icon k-i-cart" />
      {props.children} 
    </a>  
  );
};

export default ESOIHeader;

