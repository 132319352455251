import React, { useState, useEffect } from 'react';
import { Button } from '@progress/kendo-react-buttons';
import { SvgIcon } from "@progress/kendo-react-common";
import { xIcon } from "@progress/kendo-svg-icons";
import axios from '../../axiosConfig';
import { useMsal } from "@azure/msal-react";
import { truncateEmail } from '../../Utilities/utils';
import Toast from '../ToastNotification';
import isSupportedTypes from '../../Helpers/isSupportedTypes';

const AssignButton = ({ field, dataItem, updateData, token, onAssignOrder, onUnassignOrder, onErrorAssign, modalError, disabled, onFetchData, employeeRole, employeeData}) => {
  const [assigned, setAssigned] = useState(dataItem[field] != 'Not Assigned' && dataItem[field] != 'Assign');
  const {accounts} = useMsal();
  const [profileImgAssigned, setProfileImgAssigned] = useState();
  const [toast, setToast] = useState(null);
  const [idtoast, setIdtoast] = useState(1);  

  const downloadFile = async (imageName) => {
    setIdtoast(idtoast + 1);
    setToast({ showToast: false });
    let errorMessage;
    let fileName = imageName;

    if (token) {
      const downloadUrl = `/download/blob?&fileName=${encodeURIComponent(imageName)}&departmentsFolder=${encodeURIComponent("")}&departmentFolder=${encodeURIComponent("")}&orderNumFolder=${""}&containerName=${encodeURIComponent("employeeprofileimages")}&isProfileImage=${encodeURIComponent(true)}`;
      try {
        const response = await axios.get(downloadUrl, {
          headers: {
            Authorization: token,
          },
          responseType: 'blob',
        });

      const blob = new Blob([response.data], { type: response.headers['content-type'] });
      const imageUrl = URL.createObjectURL(blob);

      if (imageUrl === null) {
        setProfileImgAssigned('/DefaultProfile.png');
      }
      else {
        setProfileImgAssigned(imageUrl);
      }

      } catch (error) {
        if (error.response) {

          const status = error.response.status;
          const errorMessages = {
            401: 'Expired Token',
            500: 'The file does not exist on the blob storage'
          };

          errorMessage = errorMessages[status] || 'An error occurred';
        } else {
          errorMessage = error.message || 'An unexpected error occurred';
        }

        setToast({ showToast: true, message: errorMessage, type: 'error' });
      }
    }
  };

  function getEmployeeTitleRelId(designerType) {      
    let employeeTitleRelIdValue;

    employeeData.forEach(item => {
        if (item.employeeTitleId === designerType) {
            employeeTitleRelIdValue = item.employeeTitleRelId;
        }
    });

    return employeeTitleRelIdValue; 
  }

  const handleAssign = async (e) =>{
    const updatedData = { ...dataItem, [field]: e.target.value };
    updateData(updatedData);
    setAssigned(dataItem[field]);  

    const designerType = field  === 'designerChecker' ? 2 : 1;

    let valuesList = [];

    const mappedValues = {
      orderDetailId: dataItem.orderDetailId,
      employeeEmail: accounts[0].username,
      employeeTitleId: designerType,
      hoursActual: 0,
      author: truncateEmail(accounts[0].username,50),
      isActive: 1,
      userRole: employeeRole,
      employeeTitleRelId: getEmployeeTitleRelId(designerType),
    };

    valuesList = [mappedValues];
    
      try {
        if (token && dataItem.orderDetailId) {                      
          const response = await axios.post(
            `orderAssignation/`,            
             valuesList,            
            {
              headers: {
                Authorization: token,
              },
            }
          );
          if(response?.data?.alreadyAssignedOrders[0])
          {
            downloadFile(response.data.alreadyAssignedOrders[0].imageURL);
            modalError(response.data.alreadyAssignedOrders[0].imageURL, response.data.alreadyAssignedOrders[0].errorMessage);
          }
          else if (response?.data?.successfullyAssignedOrders[0] === dataItem.orderDetailId) {            
            if(designerType !== 2 && isSupportedTypes(dataItem.itemType)) //Update status in detailer assign
            {
              const orderStatus= dataItem.esoistatusDesc === 'Ready for Drafting' ? 3 : 1;

              handleStatusChange(orderStatus);
            }
            
            onAssignOrder();
          }
      }
      onFetchData();
    } 
    catch (error) {            
      // if(error.response.data.errorType === 1){                       
      //   downloadFile(error.response.data.imageURL);
      //   modalError(error.response.data.imageURL, error.response.data.errorMessage);
      // }
      // else {
      onErrorAssign(error);
      // }                      
    };          
};

const handleStatusChange = async (status) => {

  try {             
    const updateUrl = `/updateOrderStatus/${encodeURIComponent(dataItem.orderDetailId)}?esoiStatusId=${encodeURIComponent(status)}&Author=${encodeURIComponent(truncateEmail(accounts[0].username,50))}`;

    const response = await axios.put(
      updateUrl,
      null, 
      {
        headers: {
          Authorization: token,
          'Content-Type': 'application/json', 
        }
      }
    );     
    onFetchData();
  }
  catch (error) {
    onErrorAssign(error);
  };                 
};

  const handleUnassign = async (e) =>{
    const updatedData = { ...dataItem, [field]: e.target.value };
    
    updateData(updatedData);
    setAssigned(!dataItem[field]);  
    const userName = accounts[0].name;
    
    const designerType = field  === 'designerChecker' ? 2 : 1;

    let valuesList = [];

    const mappedValues = {
      orderDetailId: dataItem.orderDetailId,
      employeeEmail: accounts[0].username,
      employeeTitleId: designerType,
      hoursActual: 0,
      author: truncateEmail(accounts[0].username,50),
      isActive: 0,
      userRole: employeeRole,
      employeeTitleRelId: getEmployeeTitleRelId(designerType),
    };

    valuesList = [mappedValues];

      try {
        if(designerType === 2) //checker
        {                  
          //only allow unnasign if order is not released  
          if ((token && dataItem.orderDetailId && dataItem.designerChecker === userName && dataItem.esoistatusDesc !== "Released") ||
                (token && dataItem.orderDetailId && employeeRole===3 && dataItem.esoistatusDesc !== "Released")) {            
            const response = await axios.post(
              `orderAssignation/`,            
               valuesList,            
              {
                headers: {
                  Authorization: token,
                },
              }
            ); 
            
            if (response?.data?.removedAssignments[0] === dataItem.orderDetailId) {
              onUnassignOrder();
            }
          }
          else
          {            
            if(dataItem.esoistatusDesc === "Released")
              onAssignOrder('Cannot change orders status after release.', 'error');
            else
              onAssignOrder('Cannot unassign orders not assigned to you.', 'error');
          }
        }
        else //detailer
        {          
          //only allow unnasign if order is not released
          if ((token && dataItem.orderDetailId && dataItem.designerDetail === userName && dataItem.esoistatusDesc !== "Released") ||
            ( token && dataItem.orderDetailId && employeeRole===3 && dataItem.esoistatusDesc !== "Released")) {            
            const response = await axios.post(
              `orderAssignation/`,            
               valuesList,            
              {
                headers: {
                  Authorization: token,
                },
              }
            );
            if (response?.data?.removedAssignments[0] === dataItem.orderDetailId) {

              if(isSupportedTypes(dataItem.itemType)) //Update status to the previous known
              {
                const orderStatus= dataItem.esoistatusDesc === 'Order Processing' ? 2 : 8;

                handleStatusChange(orderStatus);
              }
              
              onAssignOrder();

              onUnassignOrder();
            }
          }
          else
          {            
            if(dataItem.esoistatusDesc === "Released")
              onAssignOrder('Cannot change orders status after release.', 'error');
            else
              onAssignOrder('Cannot unassign orders not assigned to you.', 'error');
          }
        }
        onFetchData();
    } catch (error) {
      onErrorAssign(error);
    };  
  };

  let borderColorCase;
  let colorCase;
  if (!assigned && !disabled) {
    borderColorCase = '#0097E0';
    colorCase = '#0097E0';
  } else if (!assigned && disabled) {
    borderColorCase = '#828282';
    colorCase = '#828282';
  } else {
    borderColorCase = 'transparent';
    colorCase = '#4C4C4C';
  }

  return (
    <div>
    {toast && <Toast key={idtoast} showToast={toast.showToast} message={toast.message} type={toast.type} />}

    <td style={{display: 'flex', flexDirection: 'row', gap:'5px', alignItems: 'center', background: dataItem.selected === true ? '#D1F3FF': 'transparent' }}>
    <button
        title={dataItem[field]}
        className= 'secondary-button roboto-15-400'
        style={{
            background: 'transparent',
            justifyContent: assigned ? 'flex-start' : 'center',
            flex: 1,
            color: colorCase,
            fontWeight: assigned ? 400 : 500,
            borderColor: borderColorCase,
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            padding: assigned ? '0px'  : '12px',
            cursor: assigned || disabled ? 'default' : 'pointer'
        }} 
        onClick={handleAssign} 
        disabled={assigned || disabled}>
        {assigned ? dataItem[field] : 'Assign'}
    </button>
    {!disabled && ( 
      <Button
          disabled= {disabled}
          onClick={handleUnassign}
          style={{
              justifyContent: 'flex-end',
              padding: '0px',
              paddingBottom: '2px',
              display: assigned ? 'block' : 'none' ,
              width: '24px',
              height: '24px',
              borderRadius: '180px',
              backgroundColor: '#F2F2F2',
              border: 'none',
          }}> <SvgIcon icon={xIcon} color='#8D8D8D'></SvgIcon>
      </Button> 
    )}
  </td>
  </div>
  );
};

export default AssignButton;
