//Maverick PlantProdFamilyId
export const MAVERICK_LINE = "Maverick II";
export const DPSA_LINE = "Rebel Applied";

//Tabs
export const GENERAL_TAB = 0;
export const NEW_ORDERS_TAB = 1;
export const CANCELLED_AND_SHIPPED_TAB = 2;

//Roles
export const READ_ONLY = 0;
export const DESIGNER_DETAILER = 1;
export const DESIGNER_CHECKER = 2;
export const SUPERVISOR = 3;
export const MANAGER = 4;