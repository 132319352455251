const UnassignmentIcon = ({ disabled }) => (
    <div>
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_8061_25629" maskUnits="userSpaceOnUse" x="0" y="0" width="25" height="24">
        <rect x="0.5" width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_8061_25629)">
        <path
          d="M24.5 9V11H18.5V9H24.5ZM8.5 4C6.29 4 4.5 5.79 4.5 8C4.5 10.21 6.29 12 8.5 12C10.71 12 12.5 10.21 12.5 8C12.5 5.79 10.71 4 8.5 4ZM8.5 10C7.4 10 6.5 9.1 6.5 8C6.5 6.9 7.4 6 8.5 6C9.6 6 10.5 6.9 10.5 8C10.5 9.1 9.6 10 8.5 10ZM8.5 13C5.83 13 0.5 14.34 0.5 17V20H16.5V17C16.5 14.34 11.17 13 8.5 13ZM14.5 18H2.5V17.01C2.7 16.29 5.8 15 8.5 15C11.2 15 14.3 16.29 14.5 17V18ZM13.01 4.05C13.93 5.11 14.5 6.49 14.5 8C14.5 9.51 13.93 10.89 13.01 11.95C14.97 11.7 16.5 10.04 16.5 8C16.5 5.96 14.97 4.3 13.01 4.05ZM17.03 13.83C17.92 14.66 18.5 15.7 18.5 17V20H20.5V17C20.5 15.55 18.91 14.49 17.03 13.83Z"
          fill={disabled ? "#B0B0B0" : "#0097E0"}
        />
      </g>
    </svg>
  </div>
);

export default UnassignmentIcon;