import React, { createContext, useContext } from "react";
import useEmployeeRole from "../Utilities/EmployeeRole";

const EmployeeRoleContext = createContext();

export const EmployeeRoleProvider = ({ children }) => {
  const { employeeRole } = useEmployeeRole();

  return (
    <EmployeeRoleContext.Provider value={employeeRole}>
      {children}
    </EmployeeRoleContext.Provider>
  );
};

export const useEmployeeRoleContext = () => {
  return useContext(EmployeeRoleContext);
};