import React, { useState, useEffect } from 'react';
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';
import { Generalcolumns, DraftHoursColumns, CancelledShippedColumns } from './Columns';
import './TabStripComponent.css'
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { SvgIcon } from "@progress/kendo-react-common";
import { chevronLeftIcon, searchIcon, circleIcon } from "@progress/kendo-svg-icons";
import { Pager } from "@progress/kendo-react-data-tools";
import { InputPrefix, TextBox, Checkbox } from "@progress/kendo-react-inputs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import axios from '../axiosConfig';
import Toast from './ToastNotification';
import { useMsal } from "@azure/msal-react";
import { formatDate } from '../Utilities/utils';
import { getCurrentLine, subscribeToCurrentLineChanges } from '../services/CurrentLineService';
import { truncateEmail } from '../Utilities/utils';
import useProfileData from '../services/ProfileDataService';
import LogOutService from '../services/LogOutService';
import useDebounce from '../Utilities/useDebounce';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import SidePanel from './SidePanel';
import { READ_ONLY } from "..//Utilities/constants";
import { useEmployeeRoleContext } from "./../services/EmployeeRoleContext";

const ChangeOrderCard  = ({ changeDate, itemExample, imageDetailerUrl, imageCheckerUrl, fetchData, loading }) => {
  
  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
    ))(({ theme }) => ({
      [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.common.black,
      },
      [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.black,
      },
    }
  ));

  let tooltipColor;
  let tooltipText;
  if (itemExample.itemType === 'SPC') {
    tooltipColor = "rgba(205, 184, 235)";
    tooltipText = `Special ${itemExample.orderType}`;
  }
  else if (itemExample.itemType === 'KIT') {
    tooltipColor = "var(--color-green-20)";
    tooltipText = `KIT ${itemExample.orderType}`;
  }
  else {
    tooltipColor = "rgba(209, 243, 255)";
    tooltipText = `Standar ${itemExample.orderType}`;
  }

  const viewIcon = [
    <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9 12.4517C9.9375 12.4517 10.7344 12.1235 11.3906 11.4673C12.0469 10.811 12.375 10.0142 12.375 9.07666C12.375 8.13916 12.0469 7.34229 11.3906 6.68604C10.7344 6.02979 9.9375 5.70166 9 5.70166C8.0625 5.70166 7.26562 6.02979 6.60938 6.68604C5.95312 7.34229 5.625 8.13916 5.625 9.07666C5.625 10.0142 5.95312 10.811 6.60938 11.4673C7.26562 12.1235 8.0625 12.4517 9 12.4517ZM9 11.1017C8.4375 11.1017 7.95938 10.9048 7.56563 10.511C7.17188 10.1173 6.975 9.63916 6.975 9.07666C6.975 8.51416 7.17188 8.03604 7.56563 7.64229C7.95938 7.24854 8.4375 7.05166 9 7.05166C9.5625 7.05166 10.0406 7.24854 10.4344 7.64229C10.8281 8.03604 11.025 8.51416 11.025 9.07666C11.025 9.63916 10.8281 10.1173 10.4344 10.511C10.0406 10.9048 9.5625 11.1017 9 11.1017ZM9 14.7017C7.175 14.7017 5.5125 14.1923 4.0125 13.1735C2.5125 12.1548 1.425 10.7892 0.75 9.07666C1.425 7.36416 2.5125 5.99854 4.0125 4.97979C5.5125 3.96104 7.175 3.45166 9 3.45166C10.825 3.45166 12.4875 3.96104 13.9875 4.97979C15.4875 5.99854 16.575 7.36416 17.25 9.07666C16.575 10.7892 15.4875 12.1548 13.9875 13.1735C12.4875 14.1923 10.825 14.7017 9 14.7017Z" fill="#0097E0"/>
    </svg>
  ];

  // SidePanel functionalities
  const [openForm, setOpenForm] = useState(false);
  const employeeRole = useEmployeeRoleContext();

  const handleCancelEdit = () => {
    setOpenForm(false);
  };

  const [editItem, setEditItem] = useState();
  const enterEdit  = (estimate, designerType, showErrorToast) => {
    const updatedItem = {
      ...itemExample,
      // orderNumber: detailActHours ? detailActHours : 0,
      // orderNumberChecker: checkActHours ? checkActHours : 0,
    };

    setOpenForm(true);
    setEditItem(updatedItem);
  };

  const [toast, setToast] = useState(null);
  const [idtoast, setIdtoast] = useState(1);
  // SidePanel functionalities

  return (

    <div className='my-work-card roboto-15-400' style={{ marginBottom: '2px' }}>
    {toast && <Toast key={idtoast} showToast={toast.showToast} message={toast.message} type={toast.type} />}
      <div>
        <div className='roboto-15-400' style={{ display: 'flex', padding: '8px 16px', alignItems: 'center', justifyContent: 'space-between' }}>
          <div style={{ gap: '8px', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <div><b>SO #: </b>{itemExample.so} |</div>
            <div style={{ borderRadius: '5px', padding: '2px', background: itemExample.itemType === 'SPC' ? '#CDB8EB' : itemExample.itemType === 'KIT' ? 'var(--color-green-20)' : '#D1F3FF' }}>
              <BootstrapTooltip title={
                <div className='roboto-15-400 text-light' style={{ alignItems: 'center', marginRight: '8px' }}>
                  <SvgIcon icon={circleIcon} size={'xlarge'} color= {tooltipColor} />{tooltipText}
                </div> } placement="top">
                <b>ESOI #: </b> {itemExample.esoi}
              </BootstrapTooltip>
            </div>
            <div><b>Received on </b>{changeDate}.</div>
            <div><b>Change order affected:</b>{changeDate}.</div>
            <div><b>Shipdate change:</b> from {changeDate} to {changeDate}.</div>
          </div>
          <button className='secondary-button' style={{ minWidth: '36px', padding: '0px', border: 'none'}}
            onClick={enterEdit}
            >{viewIcon}
          </button>
        </div>        
        {openForm && (          
          <SidePanel
            cancelEdit={handleCancelEdit}
            item={editItem}
            disabled={employeeRole === READ_ONLY}
          />
        )}
      </div>
    </div>
  );
};



const TabComponentChangeOrders = () => {
  const rowStyle= {margin: '16px',  display: 'flex', justifyContent: 'space-between', flexDirection: 'row', alignItems: 'center'};

  // Zoom adjustment
  const [zoomLevel, setZoomLevel] = useState(1);
  const [gridHeight, setGridHeight] = useState(window.innerHeight - 170);
  const [infoHeight, setInfoHeight] = useState(gridHeight - 210);

  useEffect(() => {
    const handleResize = () => {
      const viewportWidth = window.innerWidth;
      const windowWidth = window.outerWidth;
      const newZoomLevel = windowWidth / viewportWidth;
      setZoomLevel(newZoomLevel);

      const newGridHeight = window.innerHeight - 170;
      setGridHeight(newGridHeight);
      const newInfoHeight = newGridHeight - 210;
      setInfoHeight(newInfoHeight);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);
  // Zoom adjustment

  const [selected, setSelected] = useState(0);
  const [dialogVisible, setDialogVisible] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState([...Generalcolumns]);

  const handleSelect = (e) => {
    setSelected(e.selected);
    switch (e.selected) {
      case 0:
        setSelectedColumns([...Generalcolumns]);
        // handleResetTableFilters();
        break;
      case 1:
        setSelectedColumns([...DraftHoursColumns]);
        // handleResetTableFilters();
        break;
      case 2:
        setSelectedColumns([...CancelledShippedColumns]);
        // handleResetTableFilters();
        break;
      default:
        break;
    }
  };

  let allColumnss;
  switch (selected) {
      case 0:
          allColumnss = Generalcolumns;
          break;
      case 1:
        // neworders
          allColumnss = DraftHoursColumns;
          break;
      case 2:
          allColumnss = CancelledShippedColumns;
          break;
      default:
          allColumnss = Generalcolumns;
          break;
  }

  // Data used for design (delete after)
  const dummyData = [
    {
        "Timestamp": "2023-07-23",
        "Cambios": [
            {
                "esoi": "Valor1",
                "so": "Valor2",
                "Campo3": "Valor3",
                "Campo4": "Valor4"
            },
            {
                "esoi": "Valor5",
                "so": "Valor6",
                "Campo3": "Valor7",
                "Campo4": "Valor8"
            }
        ]
    },
    {
        "Timestamp": "2023-07-24",
        "Cambios": [
            {
                "esoi": "Valor9",
                "so": "Valor10",
                "Campo3": "Valor11",
                "Campo4": "Valor12"
            }
        ]
    }
]; 

  // Main endpoint
  const {accounts} = useMsal();
  const userName = accounts[0].name;
  const { token } = useProfileData();
  const [toast, setToast] = useState(null);
  const [idtoast, setIdtoast] = useState(1);
  const [orders, setOrders] = useState([]);
  const { instance } = useMsal();
  const [department, setDepartment] = useState(getCurrentLine().ProdFamilyId);
  const [filterOrders, setFilterOrders] = useState(null);
  const [startFilter, setStartFilter] = useState(null);
  const [productName, setProductName] = useState(getCurrentLine().ProdFamilyName);
  const [loadingEsoi, setLoadingEsoi] = useState(null); 
  
  useEffect(() => {
    fetchData();
  }, []);

  const [totalRows, setTotalRows] = useState(0);

  const fetchData = async (esoi, filtersInfo, orderBy) => {
    try {
      setLoadingEsoi(esoi); // Set the loading state to the current item's esoi
      //Combine filters
      let finalFilter = {
        logic: 'or',
        filters: []
      };

      if (filtersInfo && filtersInfo.filters && filtersInfo.filters.length > 0) {
        finalFilter.filters = [...filtersInfo.filters];
      }
    
      if (filterOrders && filterOrders.filters && filterOrders.filters.length > 0) {
        finalFilter.filters = [...finalFilter.filters, ...filterOrders.filters];
      }
      
       if (token) {        
        const response = await axios.post(
          `/Department/pagedFilter/${encodeURIComponent('draftingDueDate')}`,
          {
            prodFamilyId: department,
            dateColumn: 'DraftingDueDate',
            sort: orderBy || 'asc',
            skip: 0,
            take: 1000,
            // myAssignedWorkOption : selectedOption,
            initFilter : JSON.stringify(startFilter, null, 2),
            // startDate: null,
            // endDate: null,
            filter: filtersInfo ? JSON.stringify(finalFilter, null, 2) : JSON.stringify(filterOrders, null, 2),
            viewAlias: 0,          
            productName: productName,
          },
          {
            headers: {
              Authorization: token,
            },
          }
        );

        setTotalRows(response.data.totalRows);

        const ordersWithIndex = response.data.items.map((order, index) => ({
          ...order,
          rowIndex: index + 1,
        }));

        setOrders(ordersWithIndex);
        //console.log(orders)

    }} catch (error) {

      if (error.response) {

        setToast({ showToast: true, message: error.response.data, type: 'error' });
        if(error.response.status === 401) LogOutService(instance);

      } else {
        setToast({ showToast: true, message:  error.message, type: 'error' });
      }
    }

    setLoadingEsoi(null);
  };

  //Pager section
  const [page, setPage] = useState({
    skip: 0,
    take: 20,
  });
  const handlePageChange = (e) => {
    setPage({
      skip: e.skip,
      take: e.take,
    });
  };
  const { skip, take } = page;


  //Dropdown filter
  const dropdownOptions = [ 'Last two months', 'Last six moths', 'Last year' ];
  const [monthRange, setMonthRange] = useState('Last two months');
  const onChange = (event) => {
    setMonthRange(event.target.value);
  };

  //Checkbox filter
  const [value, setValue] = useState(true);
  const handleChange = (event) => {
    setValue(event.value);
  };

  //ESOI filter
  const [searchText, setSearchText] = useState('');
  const debouncedSearchText = useDebounce(searchText, 600); // delay

  const handleSearch = (event) => {    
    setSearchText(event.target.value);
  };

  useEffect(() => {
    let newFilter;
    if (debouncedSearchText) {
      newFilter = { field: 'esoi', operator: 'contains', value: debouncedSearchText };
    } else {
      newFilter = { field: 'esoi', operator: 'contains', value: '' }; 
    }
  
    setFilterOrders(prevFilterOrders => {
      let currentFilters = prevFilterOrders?.filters || [];
      currentFilters = currentFilters.filter(filter => filter.field !== 'esoi');
      let combinedFilters = [...currentFilters, newFilter];
  
      const filterObject = {
        logic: 'or',
        filters: combinedFilters
      };
  
      return filterObject;
    });
  }, [debouncedSearchText]);



  return (
    <div style={{ paddingLeft: '30px', paddingRight: '30px' }}>
    {toast && <Toast key={idtoast} showToast={toast.showToast} message={toast.message} type={toast.type} />}
      <TabStrip
        selected={selected}
        onSelect={handleSelect}
        style={{ height: gridHeight, border: 'none', display: 'flex',}}
        className='main-c-shadow'
      >
        <TabStripTab title=''>
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly'}}>
          <div className= 'roboto-15-400' style={rowStyle}>
            <Button 
              tag={Link}
              className='secondary-button roboto-15-500 blue-text' 
              to="/">
              <SvgIcon icon={chevronLeftIcon} size={'large'} color='#0097E0'></SvgIcon>
              Return
            </Button>
            <div style={{ ...rowStyle, margin: '0px'}} >
              <p>Show just assigned to me</p>
              <Checkbox
                style={{ ...rowStyle, marginLeft: '8px', marginRight: '16px'}} 
                value={value}
                onChange={handleChange}
              />
              <DropDownList
                className= 'roboto-15-400'
                title={'Selected line: '}
                data={dropdownOptions}
                value= {monthRange}
                onChange={onChange}
                style={{ maxWidth: '200px', width: '200px'}}
              />
            </div>
          </div>
          <div style={{...rowStyle, marginTop: '0px'}}>
            <p className='roboto-15-500'>Changes Notifications</p>
            <TextBox
              className= 'roboto-15-400'
              value={searchText}
              onChange={handleSearch}
              placeholder="Search ESOI..."
              style={{ maxWidth: '300px'}}
              prefix={() => (
                <InputPrefix>
                  <SvgIcon icon={searchIcon} />
                </InputPrefix>
              )}
            />
          </div>
          {/* Cards section */}
          <div className='roboto-15-400' style={{ marginLeft: '16px', paddingRight: '8px', overflowY: 'scroll', display: 'flex', flexDirection: 'column', height: infoHeight}}>
            {/* {filteredOrders.map((order, index) => ( */}
            {dummyData.map((orderGroup, index) => (
              <div key={index}>
                <p style={{margin: '16px 0px 8px 8px'}}>{formatDate(orderGroup.Timestamp, false, 1)}</p>
                {orderGroup.Cambios.map((order, subIndex) => (
                  <ChangeOrderCard
                    key={`${index}-${subIndex}`}
                    itemExample={order}
                    changeDate={formatDate(orderGroup.Timestamp, false, 1)}
                    imageUrl={order.imageUrl}
                    fetchData={() => fetchData(order.esoi)} // Pass the specific esoi to fetchData
                    loading={loadingEsoi === order.esoi} // Pass loading state specific to this item
                    imageDetailerUrl={order.imageDetailerUrl}
                    imageCheckerUrl={order.imageCheckerUrl}
                  />
                ))}
              </div>
            ))}
          </div>
          <div style={{ marginLeft: '16px', position: 'absolute', bottom: '0px', display: 'flex', width: '99.5%'}}>
            <Pager
              className="k-listview-pager commentsPaginator roboto-15-400"
              skip={skip}
              take={take}
              pageSizes = {[50, 150, 200, totalRows].filter(num => num <= totalRows)}
              onPageChange={handlePageChange}
              total={totalRows}
              buttonCount={4}
              type="input"
              previousNext={true}
              style={{padding: '0px 16px 16px 8px', borderRadius: '0px', border: '1px solid transparent', width: '100%'}}
            />
          </div>
          </div>
        </TabStripTab>
      </TabStrip>
    </div>
  );
};

export default TabComponentChangeOrders;
