import ESOINumCell from './customizedColumns/ESOINumCell'; 
import PriorityCell from './customizedColumns/PriorityCell';
import ETOCell from './customizedColumns/ETOCell';
import ESOIHeader from "./customizedColumns/ESOIHeader";
import SpecialTextCell from "./customizedColumns/SpecialTextCell";
import { TroubleshootOutlined } from '@mui/icons-material';
import { formatDate } from '../Utilities/utils';


export const DateFormatCell = (props) => {
  const { dataItem, field } = props;
  const isSchedDateColumn = field === 'buildWeek';

  return (
    <td className='roboto-15-400' style={{ backgroundColor: isSchedDateColumn ? 'rgba(255, 244, 230, 0.5)' : '' }}>
      {dataItem[field] ? formatDate(dataItem[field],false,1) : ''}
    </td>
  );
};

export const ReferenceOrdersDateFormatCell = (props) => {
  const { dataItem, field } = props;
  const isSchedDateColumn = field === 'buildWeek';  

  return (
    <td className='roboto-15-400' style={{ backgroundColor: isSchedDateColumn ? 'rgba(255, 244, 230, 0.5)' : '' }}>
      {dataItem[field] ? formatDate(dataItem[field], false, 2) : ''}
    </td>
  );
};


export const Generalcolumns = [
  { field: 'isPriority',   title: 'Priority', filterType: 'boolean', visible: true, minWidth: 40, cell: PriorityCell},
  { field: 'mgmt',   title: 'C/O', filterType: 'boolean', visible: false, minWidth: 40, header: ESOIHeader },
  { field: 'buildWeek',   title: 'Build Week', filterType: 'date', visible: true, minWidth: 150, cell: DateFormatCell},
  { field: 'bom',         title: 'BOM Revision', filterType: 'dropdown', visible: true, minWidth: 100 },
  { field: 'pcl',   title: 'PCL', filterType: 'dropdown', visible: false, minWidth: 60 },
  { field: 'so', title: 'SO Number', filterType: 'dropdown', visible: true, minWidth: 100 },
  { field: 'gostatus',  title: 'GO Status', filterType: 'dropdown', visible: true, minWidth: 100 },
  { field: 'orderQuantity',      title: 'Qty', filterType: 'number', visible: true, minWidth: 80 },
  { field: 'esoi',        title: 'ESOI Number', filterType: 'dropdown', visible: true, minWidth: 150, cell: ESOINumCell, header: ESOIHeader},
  { field: 'description', title: 'Description', filterType: 'text', visible: false, minWidth: 150 },
  { field: 'loadDate', title: 'Load Date', filterType: 'date', visible: false, minWidth: 150, cell: DateFormatCell },
  { field: 'specialText', title: 'Special Text', filterType: 'text', visible: false, minWidth: 150, cell: SpecialTextCell},
  { field: 'code', title: 'Code', filterType: 'text', visible: false, minWidth: 150 },
  { field: 'engStatus', title: 'Eng. Status', filterType: 'text', visible: false, minWidth: 150 },  
  { field: 'draftingDueDate',   title: 'Drafting Due Date', filterType: 'date', visible: true, minWidth: 150, cell: DateFormatCell},
  { field: 'draftingStartDate',   title: 'Drafting Start Date', filterType: 'date', visible: true, minWidth: 150, cell: DateFormatCell},
  { field: 'designerDetail',   title: 'Designer Detailing', filterType: 'dropdown', visible: true, minWidth: 150, cell: ESOINumCell},
  { field: 'detailEst',   title: 'Detail Est.', filterType: 'number', visible: true, minWidth: 80},
  { field: 'designerChecker',   title: 'Checker', filterType: 'dropdown', visible: true, minWidth: 150, cell: ESOINumCell},
  { field: 'checkerEst',   title: 'Check Est.', filterType: 'number', visible: true, minWidth: 80},
  { field: 'esoistatusDesc',   title: 'Status', filterType: 'dropdown', visible: true, minWidth: 180},
  { field: 'hrsLeft',   title: 'Hrs. Left', filterType: 'number', visible: true, minWidth: 80},
  { field: 'etoproc',   title: 'ETO PROC.', filterType: 'boolean', visible: true, minWidth: 80, cell: ETOCell},
  { field: 'engnoteComp',   title: 'ENG. Note Completed', filterType: 'boolean', visible: true, minWidth: 130, cell: ETOCell, header: ESOIHeader },
  { field: 'ecotype',   title: 'ECO Type', filterType: 'text', visible: false, minWidth: 150},
  { field: 'comments',   title: 'Comments', filterType: 'text', visible: false, minWidth: 150},
  { field: 'orderType', title: 'Order Type', filterType: 'dropdown', visible: false, minWidth: 80 },
  { field: 'sellingPrice', title: 'Price', filterType: 'number', visible: true, minWidth: 120 },
  { field: 'phase', title: 'Phase', filterType: 'text', visible: true, minWidth: 120 },
  { field: 'customerRequestDate', title: 'Customer Request Date', filterType: 'date', visible: true, minWidth: 150, cell: DateFormatCell},
  { field: 'line', title: 'Line', filterType: 'dropdown', visible: true, minWidth: 150},
];

export const CancelledShippedColumns = [
  { field: 'buildWeek',   title: 'Build Week', filterType: 'date', visible: true, minWidth: 150, cell: DateFormatCell},
  { field: 'bom',         title: 'BOM Revision', filterType: 'dropdown', visible: true, minWidth: 100 },
  { field: 'pcl',   title: 'PCL', filterType: 'dropdown', visible: false, minWidth: 60, },
  { field: 'so', title: 'SO Number', filterType: 'dropdown', visible: true, minWidth: 100 },
  { field: 'gostatus',  title: 'GO Status', filterType: 'dropdown', visible: true, minWidth: 100 },
  { field: 'orderQuantity',      title: 'Qty', filterType: 'number', visible: true, minWidth: 80 },
  { field: 'esoi',        title: 'ESOI Number', filterType: 'dropdown', visible: true, minWidth: 150, cell: ESOINumCell, header: ESOIHeader},
  { field: 'description', title: 'Description', filterType: 'text', visible: false, minWidth: 150 },
  { field: 'loadDate', title: 'Load Date', filterType: 'date', visible: false, minWidth: 150, cell: DateFormatCell},
  { field: 'specialText', title: 'Special Text', filterType: 'text', visible: false, minWidth: 150, cell: SpecialTextCell},
  { field: 'code', title: 'Code', filterType: 'text', visible: false, minWidth: 150 },
  { field: 'engStatus', title: 'Eng. Status', filterType: 'text', visible: false, minWidth: 150 },  
  { field: 'draftingDueDate',   title: 'Drafting Due Date', filterType: 'date', visible: true, minWidth: 150, cell: DateFormatCell},
  { field: 'draftingStartDate',   title: 'Drafting Start Date', filterType: 'date', visible: true, minWidth: 150, cell: DateFormatCell},
  { field: 'designerDetail',   title: 'Designer Detailing', filterType: 'dropdown', visible: true, minWidth: 150, cell: ESOINumCell},
  { field: 'detailEst',   title: 'Detail Est.', filterType: 'number', visible: true, minWidth: 80},
  { field: 'designerChecker',   title: 'Checker', filterType: 'dropdown', visible: true, minWidth: 150, cell: ESOINumCell},
  { field: 'checkerEst',   title: 'Check Est.', filterType: 'number', visible: true, minWidth: 80},
  { field: 'esoistatusDesc',   title: 'Status', filterType: 'dropdown', visible: true, minWidth: 180},
  { field: 'hrsLeft',   title: 'Hrs. Left', filterType: 'number', visible: true, minWidth: 80},
  { field: 'etoproc',   title: 'ETO PROC.', filterType: 'boolean', visible: true, minWidth: 80, cell: ETOCell},
  { field: 'engnoteComp',   title: 'ENG. Note Completed', filterType: 'boolean', visible: true, minWidth: 130, cell: ETOCell, header: ESOIHeader },
  // { field: 'ecotype',   title: 'ECO Type', filterType: 'text', visible: false, minWidth: 150},
  { field: 'orderType', title: 'Order Type', filterType: 'dropdown', visible: false, minWidth: 80 },
  { field: 'sellingPrice', title: 'Price', filterType: 'number', visible: true, minWidth: 120 },
  { field: 'line', title: 'Line', filterType: 'dropdown', visible: true, minWidth: 150},
];

export const NewOrdersColumns = [
  { field: 'orderLoadDate', title: 'Load Date', filterType: 'date', visible: true, minWidth: 150},
  { field: 'pcl',   title: 'PCL', filterType: 'dropdown', visible: true, minWidth: 60, },
  { field: 'so', title: 'SO Number', filterType: 'dropdown', visible: true, minWidth: 100 },
  { field: 'gostatus', title: 'GO Status', filterType: 'dropdown', visible: true, minWidth: 100 },  
  { field: 'orderQuantity', title: 'Qty', filterType: 'number', visible: true, minWidth: 80 },
  { field: 'esoi',        title: 'ESOI Number', filterType: 'dropdown', visible: true, minWidth: 150, cell: ESOINumCell, header: ESOIHeader},
  { field: 'description', title: 'Description', filterType: 'text', visible: true, minWidth: 150 },
  { field: 'itemType',         title: 'Type', filterType: 'dropdown', visible: true, minWidth: 100 },
  { field: 'specialTextTrunc', title: 'Special Text', filterType: 'text', visible: true, minWidth: 250 },
  { field: 'code', title: 'Code', filterType: 'text', visible: true, minWidth: 150 },
  { field: 'draftingHrs',   title: 'Drafting Hrs', filterType: 'number', visible: true, minWidth: 80},
  { field: 'gdate',   title: 'Earliest Drafting Completion', filterType: 'date', visible: true, minWidth: 150},
  { field: 'endate',   title: 'Earliest Eng. Completion', filterType: 'date', visible: true, minWidth: 150},
  { field: 'ProductName',   title: 'Additional Assembly Time', filterType: 'number', visible: true, minWidth: 150},
  { field: 'sellingPrice', title: 'Price', filterType: 'number', visible: true, minWidth: 120 },
  { field: 'line', title: 'Line', filterType: 'dropdown', visible: true, minWidth: 150},
]; 

export const DraftHoursColumns = [
  // { field: 'scheduleId',   title: 'scheduleId', filterType: 'number', visible: true, minWidth: 150, },
  { field: 'productionBuildWeek',   title: 'Production build week', filterType: 'date', visible: true, minWidth: 150, },
  // { field: 'newAdjustedDraftWeek',   title: 'New adjusted drafting week', filterType: 'date', visible: true, minWidth: 150, },
  { field: 'draftCompDate',   title: 'Drafting due date', filterType: 'date', visible: true, minWidth: 150, },
  { field: 'capacity',   title: 'Capacity', filterType: 'number', visible: true, minWidth: 150, },
  { field: 'draftHoursLoaded',   title: 'Drafting hrs loaded', filterType: 'number', visible: true, minWidth: 150, },
  { field: 'adjustedHours',   title: 'Adjusted hours', filterType: 'number', visible: true, minWidth: 150, },
  { field: 'draftHoursAvailableCalc',   title: 'Drafting hrs available', filterType: 'number', visible: true, minWidth: 150, },
  { field: 'notes', title: 'Notes', filterType: 'text', visible: true, minWidth: 230 },
  { field: 'reservedJobDesc', title: 'Reserved jobs description', filterType: 'text', visible: true, minWidth: 230 },
  { field: 'reservedHours',   title: 'Reserved hours', filterType: 'number', visible: true, minWidth: 150, },
]; 

export const ReferenceOrdersColumns = [
  { field: 'promiseDate',   title: 'Ship Date', filterType: 'date', visible: true, minWidth: 150, cell: ReferenceOrdersDateFormatCell},
  { field: 'orderNumber', title: 'Order Number', filterType: 'dropdown', visible: true, minWidth: 100 },  
  { field: 'bom', title: 'Sequence number', filterType: 'dropdown', visible: true, minWidth: 100 }, 
  { field: 'itemStatus',  title: 'Status', filterType: 'dropdown', visible: true, minWidth: 100 },
  { field: 'ordQty',      title: 'Qty', filterType: 'number', visible: true, minWidth: 80 },
  { field: 'item',        title: 'ESOI Number', filterType: 'dropdown', visible: true, minWidth: 150, cell: ESOINumCell, header: ESOIHeader},
  { field: 'descText', title: 'Description', filterType: 'dropdown', visible: true, minWidth: 150 },
  { field: 'orderNotesSpecialFeatures', title: 'Special Text', filterType: 'text', visible: true, minWidth: 230, cell: SpecialTextCell},
  { field: 'itemCodeString', title: 'Code', filterType: 'text', visible: false, minWidth: 150 },
  // { field: 'code', title: 'Code Length', filterType: 'text', visible: false, minWidth: 150 },
]; 