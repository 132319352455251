export const ErrorIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
      <mask id="mask0_89_2934" maskUnits="userSpaceOnUse" x="0" y="0" width="25" height="25">
        <rect x="0.785645" y="0.846191" width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_89_2934)">
        <path
          d="M12.7856 17.8462C13.069 17.8462 13.3065 17.7504 13.4981 17.5587C13.6898 17.367 13.7856 17.1295 13.7856 16.8462C13.7856 16.5629 13.6898 16.3254 13.4981 16.1337C13.3065 15.942 13.069 15.8462 12.7856 15.8462C12.5023 15.8462 12.2648 15.942 12.0731 16.1337C11.8815 16.3254 11.7856 16.5629 11.7856 16.8462C11.7856 17.1295 11.8815 17.367 12.0731 17.5587C12.2648 17.7504 12.5023 17.8462 12.7856 17.8462ZM11.7856 13.8462H13.7856V7.84619H11.7856V13.8462ZM12.7856 22.8462C11.4023 22.8462 10.1023 22.5837 8.88564 22.0587C7.66898 21.5337 6.61064 20.8212 5.71064 19.9212C4.81064 19.0212 4.09814 17.9629 3.57314 16.7462C3.04814 15.5295 2.78564 14.2295 2.78564 12.8462C2.78564 11.4629 3.04814 10.1629 3.57314 8.94619C4.09814 7.72952 4.81064 6.67119 5.71064 5.77119C6.61064 4.87119 7.66898 4.15869 8.88564 3.63369C10.1023 3.10869 11.4023 2.84619 12.7856 2.84619C14.169 2.84619 15.469 3.10869 16.6856 3.63369C17.9023 4.15869 18.9606 4.87119 19.8606 5.77119C20.7606 6.67119 21.4731 7.72952 21.9981 8.94619C22.5231 10.1629 22.7856 11.4629 22.7856 12.8462C22.7856 14.2295 22.5231 15.5295 21.9981 16.7462C21.4731 17.9629 20.7606 19.0212 19.8606 19.9212C18.9606 20.8212 17.9023 21.5337 16.6856 22.0587C15.469 22.5837 14.169 22.8462 12.7856 22.8462Z"
          fill="#FF4949"
        />
      </g>
    </svg>
  );