const AssignmentIcon = ({ disabled }) => (
  <div>
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_7110_12072" maskUnits="userSpaceOnUse" x="0" y="0" width="25" height="24">
        <rect x="0.5" width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_7110_12072)">
        <path d="M13 11.95C13.4833 11.4167 13.8542 10.8083 14.1125 10.125C14.3708 9.44167 14.5 8.73333 14.5 8C14.5 7.26667 14.3708 6.55833 14.1125 5.875C13.8542 5.19167 13.4833 4.58333 13 4.05C14 4.18333 14.8333 4.625 15.5 5.375C16.1667 6.125 16.5 7 16.5 8C16.5 9 16.1667 9.875 15.5 10.625C14.8333 11.375 14 11.8167 13 11.95ZM18.5 20V17C18.5 16.4 18.3667 15.8292 18.1 15.2875C17.8333 14.7458 17.4833 14.2667 17.05 13.85C17.9 14.15 18.6875 14.5375 19.4125 15.0125C20.1375 15.4875 20.5 16.15 20.5 17V20H18.5ZM20.5 13V11H18.5V9H20.5V7H22.5V9H24.5V11H22.5V13H20.5ZM8.5 12C7.4 12 6.45833 11.6083 5.675 10.825C4.89167 10.0417 4.5 9.1 4.5 8C4.5 6.9 4.89167 5.95833 5.675 5.175C6.45833 4.39167 7.4 4 8.5 4C9.6 4 10.5417 4.39167 11.325 5.175C12.1083 5.95833 12.5 6.9 12.5 8C12.5 9.1 12.1083 10.0417 11.325 10.825C10.5417 11.6083 9.6 12 8.5 12ZM0.5 20V17.2C0.5 16.6333 0.645833 16.1125 0.9375 15.6375C1.22917 15.1625 1.61667 14.8 2.1 14.55C3.13333 14.0333 4.18333 13.6458 5.25 13.3875C6.31667 13.1292 7.4 13 8.5 13C9.6 13 10.6833 13.1292 11.75 13.3875C12.8167 13.6458 13.8667 14.0333 14.9 14.55C15.3833 14.8 15.7708 15.1625 16.0625 15.6375C16.3542 16.1125 16.5 16.6333 16.5 17.2V20H0.5ZM8.5 10C9.05 10 9.52083 9.80417 9.9125 9.4125C10.3042 9.02083 10.5 8.55 10.5 8C10.5 7.45 10.3042 6.97917 9.9125 6.5875C9.52083 6.19583 9.05 6 8.5 6C7.95 6 7.47917 6.19583 7.0875 6.5875C6.69583 6.97917 6.5 7.45 6.5 8C6.5 8.55 6.69583 9.02083 7.0875 9.4125C7.47917 9.80417 7.95 10 8.5 10ZM2.5 18H14.5V17.2C14.5 17.0167 14.4542 16.85 14.3625 16.7C14.2708 16.55 14.15 16.4333 14 16.35C13.1 15.9 12.1917 15.5625 11.275 15.3375C10.3583 15.1125 9.43333 15 8.5 15C7.56667 15 6.64167 15.1125 5.725 15.3375C4.80833 15.5625 3.9 15.9 3 16.35C2.85 16.4333 2.72917 16.55 2.6375 16.7C2.54583 16.85 2.5 17.0167 2.5 17.2V18Z"
          fill={disabled ? "#B0B0B0" : "#0097E0"} />
      </g>
    </svg>
  </div>
);

export default AssignmentIcon;