import React from 'react';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import axios from '../../axiosConfig';
import { truncateEmail } from '../../Utilities/utils'
import { useMsal } from "@azure/msal-react";
import useProfileData from '../../services/ProfileDataService';
import isSupportedTypes from '../../Helpers/isSupportedTypes';


const CustomDropdownCell = ({ field, dataItem, updateData, disabled, onFetchData, OnStatusChangeError, employeeRole, employeeData}) => {
  const { accounts } = useMsal();  
  const { token } = useProfileData(false);  

  function validateStatusChange(selectedStatus) {

  //Disable if order has not being assigned
  if (dataItem.esoiStatusDesc === 'Released' || dataItem.designerDetail === 'Not Assigned') {
    return false;
  }

  if(isSupportedTypes(dataItem.itemType))
  {
    const statusOrder = [
      'Not started',
      'Order Processing',
      'Ready for Drafting',
      'WIP',
      'Ready for Check',
      'Submitted',
      'Released'
    ];

    const currentIndex = statusOrder.indexOf(dataItem.esoistatusDesc);    
    const selectedIndex = statusOrder.indexOf(selectedStatus);        
  
    if (selectedIndex === -1 || selectedIndex !== currentIndex + 1) {
      //if next status is not correct return -1
      return false;
    }    
    return true;
  } else {
    
    if (dataItem.esoistatusDesc === 'Released') {
      return false;
    } else {
      return true;
    }
  }
  
}

const options = {
  Special: [
    { id: 1,
      name:'Not started',
      disabled: true }, 
    { id: 2,
      name:'Order Processing',
      disabled: !validateStatusChange('Order Processing')}, 
    { id: 3,
      name:'Ready for Drafting',
      disabled: !validateStatusChange('Ready for Drafting')}, 
    { id: 4,
      name:'WIP',
      disabled: !validateStatusChange('WIP')}, 
    { id: 5,
      name:'Ready for Check',
      disabled: !validateStatusChange('Ready for Check')}, 
    { id: 6,
      name:'Submitted',
      disabled: !validateStatusChange('Submitted')}, 
    { id: 7,
      name:'Released',
      disabled: !validateStatusChange('Released')}
  ],
  Standard: [
    { id: 1,
      name:'Not started',
      disabled: true }, 
    {id: 2,
      name:'Submitted',
      disabled: !validateStatusChange('Submitted') }, 
    {id: 3,
      name:'Released',
      disabled: !validateStatusChange('Released') }
  ] 
};

function getSelectedStatusID(eValue) {      
  switch (eValue) {
    case 'Order Processing':
      return 1;
    case 'Not started':
      return 2;
    case 'WIP':
      return 3;
    case 'Done':
      return 4;
    case 'Submitted':
      return 5;
    case 'Released':
      return 6;
    case 'Ready for Check':
      return 7;
    case 'Ready for Drafting':
      return 8;
    default:
      return 2;
  }
}

  //Deassign detailer when change status to Ready for drafting
  const handleUnassign = async () =>{   
    
    let employeeTitleRelIdValue;

    employeeData.forEach(item => {
        if (item.employeeTitleId === 1) {
            employeeTitleRelIdValue = item.employeeTitleRelId;
        }
    });

    let valuesList = [];

    let mappedValues = {
      orderDetailId: dataItem.orderDetailId,
      employeeEmail: accounts[0].username,
      employeeTitleId: 1,
      hoursActual: 0,
      author: truncateEmail(accounts[0].username,50),
      isActive: 0,
      userRole: employeeRole,
      employeeTitleRelId: employeeTitleRelIdValue,
    };

    valuesList = [mappedValues];

      try {
          if (token && dataItem.orderDetailId) {            
            const response = await axios.post(
              `orderAssignation/`,            
                valuesList,            
              {
                headers: {
                  Authorization: token,
                },
              }
            ); 
          
          }

    } catch (error) {
      OnStatusChangeError();
    };  
  };

  const handleChange = async (e) => {
    const updatedData = { ...dataItem, [field]: e.target.value.name };
    const userName = accounts[0].name;

    if(dataItem.designerDetail === userName )
    {      
      updateData(updatedData);

      const correctSelectedStatus  = validateStatusChange(e.target.value.name); // currently only for special orders to check flow
      const status = getSelectedStatusID(e.target.value.name);
      if (token) {
        if(correctSelectedStatus || isSupportedTypes(dataItem.itemType)) // if order is standard do not validate flow
        try {             
          const updateUrl = `/updateOrderStatus/${encodeURIComponent(dataItem.orderDetailId)}?esoiStatusId=${encodeURIComponent(status)}&Author=${encodeURIComponent(truncateEmail(accounts[0].username,50))}`;

          const response = await axios.put(
            updateUrl,
            null, 
            {
              headers: {
                Authorization: token,
                'Content-Type': 'application/json', 
              }
            }
          );     

          if (response.status === 200) {

            if(status === 8)
            {
              handleUnassign();
            }

            onFetchData();          
          }
        }
        catch (error) {
          OnStatusChangeError(error, 'error'); 
        };         
      }
    }
    else
    {
      OnStatusChangeError('Cannot change status for orders that are not assigned to yourself.', 'error'); 
    }
  };


  const itemRenderFIN = (li, itemProps) => {
  const index = itemProps.index;
  let style = {
      color: '#1C2325',
      backgroundColor: "#F0F0F0",
      borderRadius: "180px",
      padding: "5px",
    };
  
  switch (index) {
      // Not started
      case 0: 
      if (itemProps.dataItem.disabled) {
        style.backgroundColor = '#EBEBEB';
        style.color = '#8C8C8C';
      } else {
          style.backgroundColor = "#DEEFEF";
      }
      break;
      // Submitted
      case 1:
        if (itemProps.dataItem.disabled) {
          style.backgroundColor = '#EBEBEB';
          style.color = '#8C8C8C';
        } else {
          style.backgroundColor = '#9FF28F'
        }
      break;
      // Released
      case 2:
        if (itemProps.dataItem.disabled) {
          style.backgroundColor = '#EBEBEB';
          style.color = '#8C8C8C';
        } else {
          style.color = 'white'
          style.backgroundColor = '#007F0E'
        }
      break;
      default:
          style.color = "#00F"; 
  }
  
  const itemChildren = (
      <span className = 'roboto-15-400' style={style}>
        {li.props.children}
      </span>
    );
  
  return React.cloneElement(li, li.props, itemChildren);
  };

  const itemRenderSPC = (li, itemProps) => {
    const index = itemProps.index;
    let style = {
        color: '#1C2325',
        backgroundColor: "#F0F0F0",
        borderRadius: "180px",
        padding: "5px",
        whiteSpace: 'nowrap',
      };

    switch (index) {
        // Not started
        case 0: 
          if (itemProps.dataItem.disabled) {
            style.backgroundColor = '#EBEBEB';
            style.color = '#8C8C8C';
          } else {
            style.backgroundColor = "#DEEFEF"; 
          }
        break;
        // Order P
        case 1:
          if (itemProps.dataItem.disabled) {
            style.backgroundColor = '#EBEBEB';
            style.color = '#8C8C8C';
          } else {
            style.backgroundColor = '#CDB8EB'
          }
        break;
        // Ready for drafting
        case 2:
          if (itemProps.dataItem.disabled) {
            style.backgroundColor = '#EBEBEB';
            style.color = '#8C8C8C';
          } else {
            style.backgroundColor = '#FFE7CD'
          }
        break
        // WIP
        case 3:
          if (itemProps.dataItem.disabled) {
            style.backgroundColor = '#EBEBEB';
            style.color = '#8C8C8C';
          } else {
            style.backgroundColor = '#FFF890'
          }
        break;
        // Ready for check
        case 4:
          if (itemProps.dataItem.disabled) {
            style.backgroundColor = '#EBEBEB';
            style.color = '#8C8C8C';
          } else {
            style.color = 'white'
            style.backgroundColor = '#0097E0'
            style.border = '1px solid white'
          }
        break;
        // Submitted
        case 5:
          if (itemProps.dataItem.disabled) {
            style.backgroundColor = '#EBEBEB';
            style.color = '#8C8C8C';
          } else {
            style.backgroundColor = '#9FF28F'
          }
        break;
        // Released
        case 6:
          if (itemProps.dataItem.disabled) {
            style.backgroundColor = '#EBEBEB';
            style.color = '#8C8C8C';
          } else {
            style.color = 'white'
            style.backgroundColor = '#007F0E'
          }
        break;
        default:
            style.color = "#00F"; 
    }
  // }
    
    const itemChildren = (
        <span className = 'roboto-15-400' style={style}>
          {li.props.children}
        </span>
      );
    
    return React.cloneElement(li, li.props, itemChildren);
    };
  
  const valueRender = (element) => {
      let style = {
          color: '#1C2325',
          backgroundColor: "#F0F0F0", 
          borderRadius: "180px", 
          padding: "5px", 
        };

      if (disabled) {
        style.backgroundColor = '#EBEBEB';
        style.color = '#8C8C8C';
      } else {
        switch (dataItem[field]) {
          // Not started
          case 'Not Started': 
              style.backgroundColor = "#DEEFEF";
          break;
          // Order P
          case 'Order Processing':
              style.backgroundColor = '#CDB8EB'
          break;
          // Ready for drafting
          case 'Ready for Drafting':
              style.backgroundColor = '#FFE7CD'
          break
          // WIP
          case 'WIP':
              style.backgroundColor = '#FFF890'
          break;
          // Ready for check
          case 'Ready for Check':
              style.color = 'white'
              style.backgroundColor = '#0097E0'
          break;
          // Submitted
          case 'Submitted':
              style.backgroundColor = '#9FF28F'
          break;
          // Released
          case 'Released':
              style.color = 'white'
              style.backgroundColor = '#007F0E'
          break;
          default:
              style.color = "#00F"; 
      }}

      const itemChildren = (
        <span className='roboto-15-400 element.style' style={style}>
          {/* {element.props.children} */}
          {dataItem.esoistatusDesc}
        </span>
      );
      return React.cloneElement(element, element.props, itemChildren);
  };

  const dropdownOptions = (isSupportedTypes(dataItem.itemType) ) ? options.Special : options.Standard;
  const itemRender = (isSupportedTypes(dataItem.itemType) ) ? itemRenderSPC : itemRenderFIN;

  return (      
    <td>          
      <DropDownList
        style={{backgroundColor: 'transparent', border: '1px solid transparent'}}
        defaultValue={dropdownOptions[0]}
        value={dataItem[field]} 
        data={dropdownOptions}
        dataItemKey="id"
        textField="name"
        itemRender={itemRender}
        valueRender={valueRender}
        onChange={handleChange}
        disabled={disabled}
        skipDisabledItems={true}
      />
    </td>            
  );
};


export default CustomDropdownCell;
