import * as React from "react";
import { GRID_COL_INDEX_ATTRIBUTE } from "@progress/kendo-react-grid";
import { useTableKeyboardNavigation } from "@progress/kendo-react-data-tools";
import { formatDate } from '../../Utilities/utils';

const NewOrdersCellStyle = (props) => {
  const { dataItem, field } = props;
  const { draftingHrs, gdate, endate, itemType } = dataItem;
  const navigationAttributes = useTableKeyboardNavigation(props.id);

  let backgroundColor = ''; // Default color

  if (field === 'esoi') {
    if (itemType === 'SPC') {
      backgroundColor = "rgba(205, 184, 235, 0.5)";
    } else if (itemType === 'FIN') {
      backgroundColor = "rgba(209, 243, 255, 0.5)";
    }
    else if (itemType === 'KIT') {
      backgroundColor = "var(--color-green-20)";
    }
  } else {
    let conditionCount = 0;

    if (draftingHrs !== 0) conditionCount += 1;
    if (gdate !== null) conditionCount += 1;
    if (endate !== null) conditionCount += 1;

    // Determinar el color de fondo basado en la suma de las condiciones
    if (conditionCount === 0) {
      backgroundColor = ''; // Blanco
    } else if (conditionCount === 1) {
      backgroundColor = 'rgba(253, 255, 187, 0.3)'; // Amarillo
    } else if (conditionCount === 2) {
      backgroundColor = 'rgba(209, 243, 255, 0.3)'; // Azul
    } else if (conditionCount === 3) {
      backgroundColor = 'rgba(222, 254, 203, 0.4)'; // Verde
    }
  }

  const getFontColor = (value) => {
    const fontColorMap = {
      '-1': '#CE3B2B', // Red for negative numbers
      'default': '' // Default color
    };

    return value < 0 ? fontColorMap['-1'] : fontColorMap['default'];
  };

  const color = getFontColor(dataItem[field]);
  const isDateField = ['gdate', 'endate', 'orderLoadDate'].includes(field);
  const boldStyle = field === 'capacity' || field === 'draftHoursAvailableCalc';

  if (field === 'specialTextTrunc') {
    return (
      <td
        style={{ backgroundColor, color: color, fontWeight: boldStyle ? 'bold' : 'default' }}
        className={props.className}
        colSpan={props.colSpan}
        role={"gridcell"}
        aria-colindex={props.ariaColumnIndex}
        aria-selected={props.isSelected}
        {...{ [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex }}
        {...navigationAttributes}
        title={dataItem.specialTextTrunc}
      >
        <a
          className='roboto-15-400'
          style={{ textDecoration: 'underline', color: '#0097E0', width: '100%', background: 'transparent', cursor: 'pointer' }}
          onClick={() => props.enterEdit(dataItem)}
        >
          {dataItem.specialTextTrunc?.length > 60
            ? `${dataItem.specialTextTrunc.substring(0, 60)}...`
            : dataItem.specialTextTrunc}
        </a>
      </td>
    );
  }

  return (
    <td
      className='roboto-15-400'
      style={{ backgroundColor, color: color, fontWeight: boldStyle ? 'bold' : 'default' }}
    >
      {isDateField ? formatDate(dataItem[field], false, 1) : (dataItem[field] !== undefined ? dataItem[field] : '')}
    </td>
  );
};

export default NewOrdersCellStyle;
