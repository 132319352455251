import React, { useState, useEffect, useCallback } from 'react';
import { downArrowName, TabStrip, TabStripTab } from '@progress/kendo-react-layout';
import ColumnSelectorDialog from './ColumnSelectorDialog';
import GeneralGrid from './GeneralGrid';
import CancelledShippedGrid from './CancelledShippedGrid';
import NewOrdersGrid from './NewOrdersGrid';
import { Generalcolumns, NewOrdersColumns, CancelledShippedColumns } from './Columns';
import { Button } from '@progress/kendo-react-buttons';
import DateRangePicker from './datePicker/DateRangePicker';
import './TabStripComponent.css'
import { Chip, ChipList } from "@progress/kendo-react-buttons";
import useProfileData from '../services/ProfileDataService';
import FilterDropdown from './FilterDropDown';
import { caretAltDownIcon, xIcon } from "@progress/kendo-svg-icons";
import { Dialog } from "@progress/kendo-react-dialogs";
import { SvgIcon } from "@progress/kendo-react-common";
import Modal from './Modal';
import Toast from './ToastNotification'; 
import CheckboxMAW from './../components/CheckboxMaw';
import { useNavigate } from 'react-router-dom';
import SavedFiltersModal from './SavedFiltersModal';
import { getCurrentLine} from '../services/CurrentLineService';
import {GENERAL_TAB} from "../Utilities/constants"
import { READ_ONLY } from "..//Utilities/constants";
import { useEmployeeRoleContext } from '../services/EmployeeRoleContext';
import AssignmentIcon from './Atoms/Icons/AssignmentIcon';
import UnassignmentIcon from './Atoms/Icons/UnassignmentIcon';


export const UnassignChips = ({ chipsData, setChipsData, isDetailer, setChipsRemoved }) => {
  const [size, setSize] = useState("medium");
  const [fillMode, setFillMode] = useState("solid");
  const [rounded, setRounded] = useState("full");  

    //reset all filters by checker or detail
    const handleResetFilter = () => {
      setChipsData(prevState => {
        const removedDesignerChecker = isDetailer ? [] : prevState.designerChecker;
        const removedDesignerDetail = isDetailer ? prevState.designerDetail : [];
    
        setChipsRemoved(prevRemoved => ({
          ...prevRemoved,
          designerChecker: isDetailer
            ? prevRemoved.designerChecker
            : [
                ...(prevRemoved.designerChecker || []),
                ...removedDesignerChecker.filter(
                  chip => !(prevRemoved.designerChecker || []).some(existingChip => existingChip.designerName === chip.designerName)
                )
              ],
          designerDetail: isDetailer
            ? [
                ...(prevRemoved.designerDetail || []),
                ...removedDesignerDetail.filter(
                  chip => !(prevRemoved.designerDetail || []).some(existingChip => existingChip.designerName === chip.designerName)
                )
              ]
            : prevRemoved.designerDetail,
        }));
    
        return {
          ...prevState,
          designerChecker: isDetailer ? prevState.designerChecker : [],
          designerDetail: isDetailer ? [] : prevState.designerDetail,
        };
      });
    };
    
    //remove one designer
    const clearFilter = (field) => {      
      setChipsData(prevState => {
        const removedDesignerChecker = isDetailer
          ? []
          : prevState.designerChecker.filter(chip => chip.designerName === field);
        const removedDesignerDetail = isDetailer
          ? prevState.designerDetail.filter(chip => chip.designerName === field)
          : [];
    
        setChipsRemoved(prevRemoved => ({
          ...prevRemoved,
          designerChecker: isDetailer
            ? prevRemoved.designerChecker
            : [
                ...(prevRemoved.designerChecker || []),
                ...removedDesignerChecker.filter(
                  chip => !(prevRemoved.designerChecker || []).some(existingChip => existingChip.designerName === chip.designerName)
                )
              ],
          designerDetail: isDetailer
            ? [
                ...(prevRemoved.designerDetail || []),
                ...removedDesignerDetail.filter(
                  chip => !(prevRemoved.designerDetail || []).some(existingChip => existingChip.designerName === chip.designerName)
                )
              ]
            : prevRemoved.designerDetail,
        }));
    
        const updatedDesignerChecker = isDetailer
          ? prevState.designerChecker
          : prevState.designerChecker.filter(chip => chip.designerName !== field);
        const updatedDesignerDetail = isDetailer
          ? prevState.designerDetail.filter(chip => chip.designerName !== field)
          : prevState.designerDetail;
    
        return {
          ...prevState,
          designerChecker: updatedDesignerChecker,
          designerDetail: updatedDesignerDetail,
        };
      });       
    };

  const renderChips = (data) => {
    if (!Array.isArray(data)) {
      return null; 
    }

    const uniqueData = Array.from(new Set(data.map(item => item.designerName)))
      .map(name => {
        return data.find(item => item.designerName === name);
      });

    return uniqueData.map((item, index) => (
      <div key={index} style={{ display: 'flex', marginRight: '5px', marginTop: '10px' }} title={item.esoi}>
        <Chip
          className='ml-2 mb-2 customChipBackground roboto-15-400'
          size={size}
          fillMode={fillMode}
          rounded={rounded}
          removable={true}
          onRemove={() => clearFilter(item.designerName)}
          text={item.designerName}
        />
      </div>
    ));
  };

  return (
    <div style={{width: 'inherit'}}>
      <div style= {{display: 'flex', justifyContent: 'space-between'}}>
        <div>
          {renderChips(chipsData)}
        </div>
        <div style={{display: 'flex', justifyContent: 'flex-end', flexDirection: 'column'}}>
          <button
            className="roboto-15-500 secondary-button blue-text"
            onClick={handleResetFilter}
          > {'Unassign all'}
          </button>
        </div>
      </div>
    </div>
  );
};


const TabStripComponent = () => {
  const storedCurrentTab = localStorage.getItem('currentTab');
  const storedCurrentDropDownFilters = localStorage.getItem('dropDownFiltersGeneralView');

  const [selected, setSelected] = useState(storedCurrentTab ? parseInt(storedCurrentTab, 10) : GENERAL_TAB);
  const [dialogVisible, setDialogVisible] = useState(false);
  const initialColumns = selected === 1 ?[...NewOrdersColumns] : selected === 0 ? [...Generalcolumns] : [...CancelledShippedColumns];
  const [selectedColumns, setSelectedColumns] = useState(initialColumns);

  const [dateRange, setDateRange] = useState({ startDate: null, endDate: null });
  const { token } = useProfileData(false);
  const [storedColumns, setStoredColumns] = useState(null); 

  const handleDateRangeChange = (startDate, endDate) => {
    setDateRange({ startDate, endDate });
  }; 

  const [removeFilter, setRemoveFilter] = useState(false)
  const [dropdownFilters, setDropdownFilters] = useState( storedCurrentDropDownFilters ? JSON.parse(storedCurrentDropDownFilters) : [])

  //Add special properties like cell and header to the selected load columns
  const loadColumnsInfo = async () => {  
    
    let storedColumnsConf = null;

    if(selected === 0)
     storedColumnsConf = localStorage.getItem('columnsGeneralView');
    else if(selected === 2)
     storedColumnsConf = localStorage.getItem('columnsCanShippView');

    if (storedColumnsConf) {
      const parsedColumns = JSON.parse(storedColumnsConf);
      const updatedColumns = parsedColumns.map((column) => {
          const matchingColumn = selectedColumns.find(selectedColumn => selectedColumn.field === column.field);
          return {
              ...column,
              cell: matchingColumn ? matchingColumn.cell : null,
              header: matchingColumn ? matchingColumn.header : null
          };
      });
      
      if(initialColumns.length === updatedColumns.length)
      {
        setStoredColumns(updatedColumns);
        setSelectedColumns(updatedColumns);
      }
    }
  }

  useEffect(() => {   
    if(selected !== 1) loadColumnsInfo();          
  }, [token, selected]);

  const handleSelect = (e) => {     
    setSelected(e.selected);
    resetCodeStringFilters();
    localStorage.setItem('currentTab', e.selected); 

    switch (e.selected) {
      case 0:
        if (storedColumns) {          
          setSelectedColumns(storedColumns);
        } 
        else
        {
          setSelectedColumns([...Generalcolumns]);
        }        
        handleResetTableFilters();
        break;
      case 1:
        setSelectedColumns([...NewOrdersColumns]);
        handleResetTableFilters();
        break;
      case 2:
        if (storedColumns) {
          setSelectedColumns(storedColumns);
        } 
        else
        {
          setSelectedColumns([...CancelledShippedColumns]);
        }
        handleResetTableFilters();
        break;
      default:
        break;
    }
  };

  let allColumnss;
  switch (selected) {
      case 0:
        if (storedColumns) {          
          
          allColumnss = storedColumns;
        } 
        else
        {
          allColumnss = Generalcolumns;
        }          
          break;
      case 1:
        // neworders
          allColumnss = NewOrdersColumns;
          break;
      case 2:
        if (storedColumns) {          
          
          allColumnss = storedColumns;
        } 
        else
        {
          allColumnss = CancelledShippedColumns;
        }
          break;
      default:
          allColumnss = Generalcolumns;
          break;
  }

  const handleSaveColumns = (columnField) => {
    setSelectedColumns((prevSelectedColumns) => {
      const newColumns = prevSelectedColumns.map((column) => {
        if (column.field === columnField) {
          return { ...column, visible: !column.visible };
        }
        return column;
      });

      if(selected === 0)
        localStorage.setItem('columnsGeneralView', JSON.stringify(newColumns, null, 2));
      else if(selected === 2)
        localStorage.setItem('columnsCanShippView', JSON.stringify(newColumns, null, 2));
      
      return newColumns;
    });
  };

  const handleResetColumns = () => {        
    switch (selected) {
      case 0:
        localStorage.setItem('columnsGeneralView', JSON.stringify(Generalcolumns, null, 2));
        loadColumnsInfo();
        setSelectedColumns([...Generalcolumns]);
        break;
      case 1:
        setSelectedColumns([...NewOrdersColumns]);
        break;
      case 2:
        localStorage.setItem('columnsCanShippView', JSON.stringify(CancelledShippedColumns, null, 2));
        loadColumnsInfo();
        setSelectedColumns([...CancelledShippedColumns]);
        break;
      default:
        break;
    }
    
  };

  const handleDialogClose = () => {
    setDialogVisible(false);
  };  

  const dateFields = ["buildWeek", "draftingStartDate", "draftingDueDate", "loadDate", "promiseDate", "customerRequestDate"];
  const storedFilter = localStorage.getItem('filtersGeneralView');  
  const storedCurrentFilter = localStorage.getItem('currentFilterGeneralView'); 
  const storedPrevFilter = localStorage.getItem('currentPrevFilterGeneralView');

  //Parse dates
  const parseFiltersFromStorage = (storedFilter) => {    
    if (storedFilter && storedFilter !== "null") {        
        const parsedFilter = JSON.parse(storedFilter);
        return {
            ...parsedFilter,
            filters: parsedFilter.filters?.map(filterItem => {
                return {
                    ...filterItem,
                    value: dateFields.includes(filterItem.field) ? new Date(filterItem.value) : filterItem.value
                };
            })
        };
    } else {        
        return null;
    }
};
  
  const [filter, setFilter] = useState(storedFilter ? parseFiltersFromStorage(storedFilter) : null);
  const [prevFilter, setPrevFilter] = useState(storedPrevFilter ? JSON.parse(storedPrevFilter) : []);
  const [currentFilter, setCurrentFilter] = useState(storedCurrentFilter ? JSON.parse(storedCurrentFilter) : []);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [emptyDate, setEmptyDate] = useState(startDate !== null);
  

  const handleResetTableFilters = () => {
    setFilter(null);
    setPrevFilter([]);
    setCurrentFilter([]);    
    localStorage.setItem('dropDownFiltersGeneralView', JSON.stringify([], null, 2));
    localStorage.setItem('currentFilterGeneralView', JSON.stringify([], null, 2)); 
    localStorage.setItem('currentPrevFilterGeneralView', JSON.stringify([], null, 2));
  };

  const handleResetFilter = () => {    

    handleResetTableFilters();
    setStartDate(null);
    setEndDate(null);
    setDateRange({ startDate: null, endDate: null });

    resetCodeStringFilters();

    localStorage.removeItem('startDate');
    localStorage.removeItem('endDate');
    localStorage.removeItem('dropDownFiltersGeneralView');
    localStorage.removeItem('filtersGeneralView');
    localStorage.removeItem('currentFilterGeneralView');
    localStorage.removeItem('currentPrevFilterGeneralView');
    setEmptyDate(true);
  }

  const resetCodeStringFilters = () => {    

    //Clear code string filters and chips selections
    setCodeitemsChipsData([]);
    setSelectedCodeItemsOptions([]);
    setCodeItemsFiltersSelected([]);
  }

  const [size, setSize] = React.useState("medium");
  const [fillMode, setFillMode] = React.useState("solid");
  const [rounded, setRounded] = React.useState("full");

  const groupedFilters = filter && filter.filters ? filter.filters.reduce((acc, curr) => {
    if (!acc[curr.field]) {
      acc[curr.field] = [];
    }

    let formattedValue = curr.value;
    if (typeof curr.value === 'boolean') {
      // formating boolean value
      formattedValue = curr.value ? "Yes" : "No";
    } else if (dateFields.includes(curr.field)) {
      // formating date value 
      formattedValue = new Date(curr.value) instanceof Date && !isNaN(new Date(curr.value))
        ? new Date(curr.value).toLocaleDateString('en-US')
        : curr.value;
    }

    acc[curr.field].push(formattedValue);
    return acc;
  }, {}) : {};

  const clearFilter = (field) => {    
    handleRemoveAllFilters(field);
  };

  const clearCodeItemFilter = (filterKey) => {

    // update chip list
    setCodeitemsChipsData(prevData => {
      const newData = { ...prevData };
      delete newData[filterKey]; 
      return newData;
    });
    
    //update options selected
    setCodeItemsFiltersSelected(prevFilters => {
      return prevFilters.filter(item => item.description !== filterKey);
    });

    setSelectedCodeItemsOptions(prevFilters => {
      return prevFilters.filter(item => item.description !== filterKey);
    });

  };

  const extraData = { filtersLabel: ["Active filters:"]};

  const chipsData = {
    ...extraData,
    ...groupedFilters
  };

  
const handleRemoveAllFilters = (currentFilterField) => {    
    setFilter((prevFilter) => {
      if (!prevFilter) {
        return null;
      }

      // Remove the filter related to the current custom filter field
      const updatedFilters = prevFilter.filters?.filter((filter) => filter.field !== currentFilterField) || [];

      // If there are no filters left, return null to clear the filter
      return updatedFilters.length > 0 ? { ...prevFilter, filters: updatedFilters } : null;
    });

    setRemoveFilter(true);
    
    const allFields = filter?.filters?.map(filter => filter.field) || [];
    const updatedFields = allFields.filter(item => item !== currentFilterField);
    const allFields2 = [...new Set(updatedFields)];

    setPrevFilter(allFields2);
    setDropdownFilters(filter?.filters ? filter.filters : []);

    localStorage.setItem('dropDownFiltersGeneralView', JSON.stringify(filter?.filters ? filter.filters : [], null, 2));    
    localStorage.setItem('currentPrevFilterGeneralView', JSON.stringify(allFields2, null, 2));
};

  
  const [exportExcel, setExportExcel] = useState(false);
  const [exportExcelGeneral, setExportExcelGeneral] = useState(false);
  const [exportExcelCanceledShipped, setExportExcelCanceledShipped] = useState(false);

  const fixedValues = [{
    "columnName": "checkbox",
    "uniqueValues": [
      "Assign to me as Designer for detailing",  
      "Assign to me as a Designer for checking"
        
    ]
  },
  {
      "columnName": "priority",
      "uniqueValues": [
          "Past due date",
          "Near to past due date",
          "Far from due date"
      ]
  }]

  const [selectedOptions, setSelectedOptions] = useState([]);
  const [openAssign, setOpenAssign] = useState(false);
  const [openUnassign, setOpenUnassign] = useState(false);
  const [unassignOptions, setUnassignOptions] = useState([]);
  const [chipsRemoved, setChipsRemoved] = useState({
    designerChecker: [],
    designerDetail: []
  });
  const [modalVisible, setModalVisible] = useState(false);
  const [toast, setToast] = useState(null);
  const [idtoast, setIdtoast] = useState(1);
  const [isUnassign, setIsUnassign] = useState(false);
  const [codeItemsFiltersSelected, setCodeItemsFiltersSelected] = useState([]);
  const [scheduleData, setScheduleData] = useState({ TotalRows: 0, Items: [] });
  const [scheduleDataNewOrders, setScheduleDataNewOrders] = useState({ TotalRows: 0, Items: [] });
  const [codeItemsChipsData, setCodeitemsChipsData] = useState([]);
  const employeeRole = useEmployeeRoleContext();

  const handleEditAssign = () => {
    setUnassignOptions([]);

    if (selectedOptions?.length > 0) {
      setOpenAssign(true);
    }
    else if (selectedOptions?.length === 0) {
      showToastSelection();
    }
    
  }

  const handleEditUnassign = () => {
    // Multiple unassign

    const designerCheckerData = selectedOptions
    .filter(option => option.designerChecker !== "Not Assigned")
    .map(option => ({
      designerName: option.designerChecker,
      esoi: option.esoi
    }));

    const designerDetailData = selectedOptions
      .filter(option => option.designerDetail !== "Not Assigned")
      .reduce((acc, option) => {
        if (!acc[option.designerDetail]) {
          acc[option.designerDetail] = [];
        }
        acc[option.designerDetail].push(option.esoi);
        return acc;
      }, {});

    const designerDetail = Object.keys(designerDetailData).map(designerName => ({
      designerName,
      esoi: designerDetailData[designerName].join(', ')
    }));

    const unassignResult = {
      designerChecker: designerCheckerData,
      designerDetail: designerDetail
    };
    setUnassignOptions(unassignResult);

    // Multiple unassign

    if (selectedOptions?.length > 0) {
      setOpenUnassign(true);
    }
    else if (selectedOptions?.length === 0) {
      showToastSelection();
    }
  }

  const [drafthoursEdit, setDrafthoursEdit] = useState(false);

  const handleAcceptUnassignation = () => {    
    setIsUnassign(true);
    handleAcceptAssignation();
  };

  const handleAcceptAssignation = () => {
    setOpenAssign(false);
    setOpenUnassign(false);
    setModalVisible(false);
    setDrafthoursEdit(!drafthoursEdit);
  };
    
  const handleCancelEdit = () => {
    //Reset designers selected to remove
    setChipsRemoved({
      designerChecker: [],
      designerDetail: []
    });

    setOpenAssign(false);
    setOpenUnassign(false);
    setModalVisible(false);
  };

  const showToastSelection = async () => {
    setIdtoast(idtoast + 1);
    setToast({ showToast: false });
    setToast({ showToast: true, message: 'Please select at least one week to edit', type: 'info' });
  };

  const [values, setValues] = useState([]);
  const updateFilters = useCallback((field, newValue) => {
    setValues(newValue);
  }, []);


  const handleApplyCodeItemsFilter = (selectedOptions) => {
    setCodeItemsFiltersSelected(selectedOptions);     
  };

  const handleShowMessage = async (text, state) => {
    setIdtoast(idtoast + 1);
    setToast({ showToast: false });
    setToast({ showToast: true, message: text, type: state });  
  };

  const navigate = useNavigate();
  const [selectedCodeItemsOptions, setSelectedCodeItemsOptions] = useState([]);

  const [showSavedFiltersModal, setShowSavedFiltersModal] = useState(false);

  const handleOpenSaveModal = () => {
    setShowSavedFiltersModal(true);
  };

  const handleCloseSaveModal = () => {
    setShowSavedFiltersModal(false);
  };

  const toCompareCode = () => {       
    if(selectedOptions.length === 2)
    {
      navigate(`/codeCompare/${selectedOptions[0].pcl}/${selectedOptions[0].esoi}/${selectedOptions[1].pcl}/${selectedOptions[1].esoi}`, {
        state: { orderData: selectedOptions } 
      });
    }
    else
      handleShowMessage("To compare codes please select two orders" ,"info");
  };  

  return (
    <div style={{ paddingLeft: '30px', paddingRight: '30px' }}>
    {toast && <Toast key={idtoast} showToast={toast.showToast} message={toast.message} type={toast.type} />}
      <div style={{ justifyContent: 'space-between', flexDirection: 'row', display: 'flex' }}>
        <div style={{ justifyContent: 'flex-start', display: 'flex', marginTop: '12px', gap: '2px', flexDirection: 'row', alignItems: 'flex-end' }}>
          <Button
            onClick={() => handleSelect({ selected: 0 })}
            className={selected === 0 ? 'main-c-shadow selected-tab roboto-15-500' : 'main-c-shadow unselected-tab roboto-15-500 blue-text'}
          >
            General
          </Button>
          <Button
            onClick={() => handleSelect({ selected: 1 })}
            className={selected === 1 ? 'main-c-shadow selected-tab roboto-15-500' : 'main-c-shadow unselected-tab roboto-15-500 blue-text'}
          >
            New Orders
          </Button>
          <Button
            onClick={() => handleSelect({ selected: 2 })}
            className={selected === 2 ? 'main-c-shadow selected-tab roboto-15-500' : 'main-c-shadow unselected-tab roboto-15-500 blue-text'}
          >
            Canceled and shipped
          </Button>
        </div>
        {/* Display DateRangePicker and ColumnSelectorDialog */}
        {(selected === 0 || selected === 2) && (
          <div style={{ justifyContent: 'flex-end', flexDirection: 'row', display: 'flex', alignItems: 'center', gap: '8px' }}>
            <DateRangePicker onDateRangeChange={handleDateRangeChange}
              emptyDate={emptyDate}
              setEmptyDate={setEmptyDate}
              startDate={startDate} setStartDate={setStartDate}
              endDate={endDate} setEndDate={setEndDate}
            ></DateRangePicker>
          {/* Display Multi-assign and unassign Button */}
            {selected === 0 && (
              <div style={{ justifyContent: 'flex-end', flexDirection: 'row', display: 'flex', alignItems: 'center', gap: '8px' }}>
                <button
                  onClick={handleEditAssign}
                  disabled = {employeeRole === READ_ONLY}
                  style={{minWidth: '0px'}}
                  className='secondary-button roboto-15-500 blue-text'> 
                  <AssignmentIcon disabled={employeeRole === READ_ONLY} />
                  <p className='hide-class'>{'Assign'}</p>
                </button>
                <button
                  onClick={handleEditUnassign}
                  disabled = {employeeRole === READ_ONLY}
                  style={{minWidth: '0px'}}
                  className='secondary-button roboto-15-500 blue-text'> 
                  <UnassignmentIcon disabled={employeeRole === READ_ONLY} />
                  <p className='hide-class'>{'Unassign'}</p>
                </button>
                <button
              className="roboto-15-500 secondary-button blue-text"
              onClick={() => setExportExcelGeneral(true)}
            > Export
            </button>
              </div>
            )}
            {selected === 2 && (
              <div
                style={{
                  justifyContent: "flex-end",
                  flexDirection: "row",
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                }}
              >
                <button
                  className="roboto-15-500 secondary-button blue-text"
                  onClick={() => setExportExcelCanceledShipped(true)}
                >
                  {" "}
                  Export
                </button>
              </div>
            )}
            <button
              className="roboto-15-500 secondary-button blue-text"
              onClick={() => setDialogVisible(true)}
              style={{ gap: '8px', width: 'fit-content', flexDirection: 'row', display: 'flex', alignItems: 'center', minWidth: '51px'}}
            >
              <div className='hide-class'>{'Columns'}</div><img className="settings-icon1 ml-5" alt="" src="/settings1.svg" />
            </button>
            <ColumnSelectorDialog
              visible={dialogVisible}
              onClose={handleDialogClose}
              onSave={handleSaveColumns}
              selectedColumns={selectedColumns}
              allColumns={allColumnss}
              onReset={handleResetColumns}
            />
          </div>
        )}
        {/* Display Export Button */}
        {selected === 1 && (
          <div style={{ justifyContent: 'flex-end', flexDirection: 'row', display: 'flex', alignItems: 'center', gap: '8px' }}>
            <button
              className="roboto-15-500 secondary-button blue-text"
              onClick={() => setExportExcel(true)}
            > Export
            </button>
          </div>
        )}
      </div>
      <TabStrip
        selected={selected}
        onSelect={handleSelect}
        style={{ border: 'none' }}
        className='main-c-shadow'
      >
        <TabStripTab title=''>
        {/* Code string functionalities */}
        <>
        {getCurrentLine().PlantProdFamilyId && (
        <div style={{ margin: '20px 15px 15px 15px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <div style={{ display: 'flex', gap: '10px' }}>
          <FilterDropdown 
            onApplyFilter={handleApplyCodeItemsFilter}
            item={scheduleData.Items}
            numFiltersApplied={codeItemsChipsData}
            setSelectedOptions={setSelectedCodeItemsOptions}
            selectedOptions={selectedCodeItemsOptions}
            handleShowMessage={handleShowMessage}
          />
          <Button                                               
                  className=' roboto-15-500 blue-text return-button'
                  onClick={handleOpenSaveModal}         
                  to="/">
                  <span className='return-text'>Saved Filters</span>
                  <SvgIcon icon={caretAltDownIcon} size={'large'} color='#0097E0'></SvgIcon>                  
          </Button>
            {showSavedFiltersModal && (
              <SavedFiltersModal 
                onClose={handleCloseSaveModal}
                handleShowMessage={handleShowMessage}
                setCodeItemsFiltersSelected={setCodeItemsFiltersSelected}
                setSelectedCodeItemsOptions={setSelectedCodeItemsOptions}
              />
            )}
          </div>
          <div>
            {getCurrentLine().PlantProdFamilyId && (
              <Button                        
                onClick={toCompareCode}          
                className='roboto-15-500 blue-text return-button' 
                to="/">
                <p className='return-text'>Compare codes</p>
              </Button>
            )}
          </div>
        </div>
        )}
      </>
       {/* Active filters chips section*/}
       {(Object.keys(chipsData)?.length > 1 || Object.keys(codeItemsChipsData)?.length > 0) && ( 
    <div className='chipListContainer'> 
     <div className='d-flex align-items-center flex-wrap'>
      {/* grid filters ChipList */}
      <div className="chipList k-form-field-wrap d-flex flex-row align-items-center flex-wrap">
        <ChipList
          data={Object.keys(chipsData)}
          selection="multiple"        
          chip={(props) => (
            <div  className='chipsContainer d-flex' title={chipsData[props.dataItem].join(', ')}>
              <Chip
                className='ml-2 mb-2 customChipBackground'
                size={size}
                fillMode={fillMode}
                rounded={rounded}
                removable={(Generalcolumns.find(col => col.field === props.dataItem)) ? true : false}
                disabled={(Generalcolumns.find(col => col.field === props.dataItem)) ? false : true}
                onRemove={() => clearFilter(props.dataItem)}
                text={
                  <div>
                    <span className='roboto-15-500' style={{display: (Generalcolumns.find(col => col.field === props.dataItem)) ? 'inline' : 'none'}}>
                      {(Generalcolumns.find(col => col.field === props.dataItem)) ? (Generalcolumns.find(col => col.field === props.dataItem)).title : "filters"}
                    </span>
                    <span className='tabSplit' style={{ display: (Generalcolumns.find(col => col.field === props.dataItem)) ? 'inline' : 'none' }}>|</span>          
                    <span className='roboto-15-400'> 
                      {props.dataItem === "mgmt"
                        ? chipsData[props.dataItem]
                            .map(item => item === "Delta" ? "Yes" : item === "New" ? "No" : item)
                            .slice(0, 10)
                            .join(', ')
                        : chipsData[props.dataItem]
                            ?.slice(0, 10)
                            .join(', ')
                      }
                      {chipsData[props.dataItem]?.length > 10 && '...'}
                    </span>
                  </div>
                }
              />
            </div>
          )}
        />
      </div>
      
      {/* Code items chip Filters */}
      <div className={"chipList k-form-field-wrap d-flex flex-row align-items-center flex-wrap"}>
        <ChipList
          data={Object.keys(codeItemsChipsData)}
          selection="multiple"
          chip={(props) => {
            // get code item number
            const matchingItem = codeItemsFiltersSelected.find(item => item.description === props.dataItem);
            
            return (
              <div className="d-flex chipsContainer" title={codeItemsChipsData[props.dataItem]?.join(', ')}>
                <Chip
                  className="ml-2 mb-2 customChipCodeBackground"
                  size={size}
                  fillMode={fillMode}
                  rounded={rounded}
                  removable={true}
                  onRemove={() => clearCodeItemFilter(props.dataItem)}
                  text={
                    <div>                      
                      <span className="roboto-15-500">
                        {matchingItem?.fiCodeItem ? `${matchingItem.fiCodeItem}. ` : ''}{props.dataItem}
                      </span>
                      <span className="tabSplit">|</span>
                      <span className="roboto-15-400">
                        {codeItemsChipsData[props.dataItem]?.slice(0, 10).join(', ')}
                        {codeItemsChipsData[props.dataItem]?.length > 10 && '...'}
                      </span>
                    </div>
                  }
                />
              </div>
            );
          }}
        />
        </div>
      </div>

        <div>
          <button
            className="roboto-15-500 secondary-button blue-text"
            onClick={handleResetFilter}
            style={{ width: '140px', border: 'none' }}
          >
            {'Clear all filters'}<img className="settings-icon1 ml-5" alt="" src="/filter.svg" />
          </button>
        </div> 
      </div>
    )}

          <GeneralGrid 
            columns={selectedColumns} 
            dateRange={dateRange} 
            filter={filter} 
            setFilter={setFilter}
            prevFilter={prevFilter}
            setPrevFilter={setPrevFilter}
            currentFilter={currentFilter}
            setCurrentFilter={setCurrentFilter}

            onRemoveAll={handleRemoveAllFilters}            
            removeFilter={removeFilter}
            setRemoveFilter={setRemoveFilter}
            setDropdownFilters={setDropdownFilters}
            dropdownFilters={dropdownFilters}

            selectedOptions = {selectedOptions}
            setSelectedOptions = {setSelectedOptions}
            onDrafthoursEdit={drafthoursEdit}
            multipleAssignation = {values}
            unassignDesigners = {unassignOptions} 
            chipsRemoved = {chipsRemoved} 
            setChipsRemoved = {setChipsRemoved}
            isUnassign = {isUnassign}
            setIsUnassign = {setIsUnassign}
            scheduleData= {scheduleData}
            setScheduleData= {setScheduleData}
            CodeStringFilters={codeItemsFiltersSelected}
            setCodeitemsChipsData={setCodeitemsChipsData}
            exportExcel={exportExcelGeneral}
            setExportExcel={setExportExcelGeneral}
          />
        </TabStripTab>
        <TabStripTab title=''>
          {/* Code string functionalities */}
          <>
          {getCurrentLine().PlantProdFamilyId && (
          <div style={{ margin: '20px 15px 15px 15px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div style={{ display: 'flex', gap: '10px' }}>
            <FilterDropdown 
              onApplyFilter={handleApplyCodeItemsFilter}
              item={scheduleData.Items}
              numFiltersApplied={codeItemsChipsData}
              setSelectedOptions={setSelectedCodeItemsOptions}
              selectedOptions={selectedCodeItemsOptions}
              handleShowMessage={handleShowMessage}
            />
            <Button                                               
                    className=' roboto-15-500 blue-text return-button'
                    onClick={handleOpenSaveModal}         
                    to="/">
                    <span className='return-text'>Saved Filters</span>
                    <SvgIcon icon={caretAltDownIcon} size={'large'} color='#0097E0'></SvgIcon>                  
            </Button>
              {showSavedFiltersModal && (
                <SavedFiltersModal 
                  onClose={handleCloseSaveModal}
                  handleShowMessage={handleShowMessage}
                  setCodeItemsFiltersSelected={setCodeItemsFiltersSelected}
                  setSelectedCodeItemsOptions={setSelectedCodeItemsOptions}
                />
              )}
            </div>
          </div>
          )}
          </>
          {/* Active filters chips section*/}
          {(Object.keys(chipsData)?.length > 1 || Object.keys(codeItemsChipsData)?.length > 0) && (  
              <div className='chipListContainer'> 
                <div className='d-flex align-items-center flex-wrap'>
                <div className="chipList k-form-field-wrap d-flex flex-row align-items-center flex-wrap">
                  <ChipList
                    data={Object.keys(chipsData)}
                    selection="multiple"        
                    chip={(props) => (
                      <div  className='chipsContainer d-flex' title={chipsData[props.dataItem].join(', ')}>
                        <Chip
                          className='ml-2 mb-2 customChipBackground'
                          size={size}
                          fillMode={fillMode}
                          rounded={rounded}
                          removable={(NewOrdersColumns.find(col => col.field === props.dataItem)) ? true : false}
                          disabled={(NewOrdersColumns.find(col => col.field === props.dataItem)) ? false : true}
                          onRemove={() => clearFilter(props.dataItem)}
                          text={
                            <div>
                              <span className='roboto-15-500' style={{display: (NewOrdersColumns.find(col => col.field === props.dataItem)) ? 'inline' : 'none'}}>{(NewOrdersColumns.find(col => col.field === props.dataItem)) ? (NewOrdersColumns.find(col => col.field === props.dataItem)).title : "filters"}</span>                                                                    
                              <span className='tabSplit' style={{ display: (NewOrdersColumns.find(col => col.field === props.dataItem)) ? 'inline' : 'none' }}>|</span>
                              <span className='roboto-15-400'>
                                {chipsData[props.dataItem]?.slice(0, 10).join(', ')}
                                {chipsData[props.dataItem]?.length > 10 && '...'}
                              </span>
                            </div>
                          }
                        />
                      </div>
                    )}
                  />
                </div>

            {/* Code items chip Filters */}
            <div className={"chipList k-form-field-wrap d-flex flex-row align-items-center flex-wrap"}>
              <ChipList
                data={Object.keys(codeItemsChipsData)}
                selection="multiple"
                chip={(props) => {
                  // get code item number
                  const matchingItem = codeItemsFiltersSelected.find(item => item.description === props.dataItem);
                  
                  return (
                    <div className="d-flex chipsContainer" title={codeItemsChipsData[props.dataItem]?.join(', ')}>
                      <Chip
                        className="ml-2 mb-2 customChipCodeBackground"
                        size={size}
                        fillMode={fillMode}
                        rounded={rounded}
                        removable={true}
                        onRemove={() => clearCodeItemFilter(props.dataItem)}
                        text={
                          <div>                            
                            <span className="roboto-15-500">
                              {matchingItem?.fiCodeItem ? `${matchingItem.fiCodeItem}. ` : ''}{props.dataItem}
                            </span>
                            <span className="tabSplit">|</span>
                            <span className="roboto-15-400">
                              {codeItemsChipsData[props.dataItem]?.slice(0, 10).join(', ')}
                              {codeItemsChipsData[props.dataItem]?.length > 10 && '...'}
                            </span>
                          </div>
                        }
                      />
                    </div>
                  );
                }}
              />
              </div>

              </div>
              <div>
                <button
                  className="roboto-15-500 secondary-button blue-text"
                  onClick={handleResetFilter}
                  style={{ width: '140px', border: 'none' }}
                >
                  {'Clear all filters'}<img className="settings-icon1 ml-5" alt="" src="/filter.svg" />
                </button>
              </div> 
            </div>
          )}
          <NewOrdersGrid 
            columns={selectedColumns} 
            dateRange={dateRange} 
            filter={filter} 
            setFilter={setFilter}
            prevFilter={prevFilter}
            setPrevFilter={setPrevFilter}
            currentFilter={currentFilter}
            setCurrentFilter={setCurrentFilter}
            disabled={true}

            onRemoveAll={handleRemoveAllFilters}            
            removeFilter={removeFilter}
            setRemoveFilter={setRemoveFilter}
            setDropdownFilters={setDropdownFilters}
            dropdownFilters={dropdownFilters}

            exportExcel={exportExcel}
            setExportExcel={setExportExcel}
            setScheduleData= {setScheduleDataNewOrders}
            CodeStringFilters={codeItemsFiltersSelected}
            setCodeitemsChipsData={setCodeitemsChipsData} 
          />
        </TabStripTab>
        <TabStripTab title=''>
          {/* Active filters chips section*/}
          {Object.keys(chipsData)?.length > 1 && ( 
            <div style={{ margin: '20px 15px 15px 15px', display: 'flex', flexDirection: 'row', gap: '10px',  justifyContent: 'space-between'}}> 
              <div style={{ display: 'flex', gap:'10px', alignItems: 'center'}}>
                <div className={"chipList k-form-field-wrap"} style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                  <ChipList
                    data={Object.keys(chipsData)}
                    selection="multiple"        
                    chip={(props) => (
                      <div style={{ display: 'flex', marginRight: '5px', marginTop: '10px' }} title={chipsData[props.dataItem].join(', ')}>
                        <Chip
                          className='ml-2 mb-2 customChipBackground'
                          size={size}
                          fillMode={fillMode}
                          rounded={rounded}
                          removable={(CancelledShippedColumns.find(col => col.field === props.dataItem)) ? true : false}
                          disabled={(CancelledShippedColumns.find(col => col.field === props.dataItem)) ? false : true}
                          onRemove={() => clearFilter(props.dataItem)}
                          text={
                            <div>
                              <span className='roboto-15-500' style={{display: (CancelledShippedColumns.find(col => col.field === props.dataItem)) ? 'inline' : 'none'}}>{(CancelledShippedColumns.find(col => col.field === props.dataItem)) ? (CancelledShippedColumns.find(col => col.field === props.dataItem)).title : "filters"}</span>
                              <span style={{ color: '#CECECE', marginLeft: '5px', marginRight: '5px', display: (CancelledShippedColumns.find(col => col.field === props.dataItem)) ? 'inline' : 'none' }}>|</span>          
                              <span className='roboto-15-400'>
                                {chipsData[props.dataItem]?.slice(0, 10).join(', ')}
                                {chipsData[props.dataItem]?.length > 10 && '...'}
                              </span>
                            </div>
                          }
                        />
                      </div>
                    )}
                  />
                </div>
              </div>
              <div>
                <button
                  className="roboto-15-500 secondary-button blue-text"
                  onClick={handleResetFilter}
                  style={{ width: '140px', border: 'none' }}
                >
                  {'Clear all filters'}<img className="settings-icon1 ml-5" alt="" src="/filter.svg" />
                </button>
              </div> 
            </div>
          )}         
          <CancelledShippedGrid 
            columns={selectedColumns} 
            dateRange={dateRange} 
            filter={filter} 
            setFilter={setFilter}
            prevFilter={prevFilter}
            setPrevFilter={setPrevFilter}
            currentFilter={currentFilter}
            setCurrentFilter={setCurrentFilter}
            disabled={true}
            onRemoveAll={handleRemoveAllFilters}
            removeFilter={removeFilter}
            setRemoveFilter={setRemoveFilter}
            setDropdownFilters={setDropdownFilters}
            dropdownFilters={dropdownFilters}
            exportExcel={exportExcelCanceledShipped}
            setExportExcel={setExportExcelCanceledShipped}
          />
        </TabStripTab>
      </TabStrip>

      { openAssign && (
      <Dialog 
        onClose={handleCancelEdit}
        height={'auto'}
        width={450}
        >
        <div style={{padding: '8px', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}} className='roboto-15-400'>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
            <p className='roboto-19-700'>{'Assign'}</p>
            <div style={{width: '100%', marginBottom: '16px'}}>
              <CheckboxMAW
              label='none'
              endPointData={fixedValues}
              field='checkbox'
              onChangeFilter={updateFilters}
              />
            </div>
            <Button
              onClick={handleCancelEdit}
              style={{
                  position: 'absolute', right: '24px',
                  width: '24px',
                  height: '24px',
                  borderRadius: '180px',
                  backgroundColor: '#F2F2F2',
                  border: 'none',
                  padding: 0,
              }}> <SvgIcon icon={xIcon} color='#8D8D8D'></SvgIcon>
            </Button> 
          </div>
          <div style={{display: 'flex', justifyContent: 'flex-end', gap: '8px' }}>
            <button onClick={handleCancelEdit} className='secondary-button roboto-15-500 blue-text'>Cancel</button>
            <button onClick={() => setModalVisible(true)} className='primary-button roboto-15-500'>Accept</button>
          </div>
        </div>
      </Dialog>
      )}

      { openUnassign && (
      <Dialog 
        onClose={handleCancelEdit}
        height={'auto'}
        width={450}
        >
        <div style={{padding: '8px', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}} className='roboto-15-400'>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
            <p className='roboto-19-700'>{'Unassign'}</p>
            <div style={{borderTop: unassignOptions?.designerDetail?.length > 0, flexDirection: 'column', paddingBottom: '8px'}}>
              <p className='roboto-15-500' style={{paddingBottom: '8px', textAlign: 'initial'}}>{'Assigned for Checking'}</p>
              <UnassignChips
                chipsData={unassignOptions?.designerChecker}
                setChipsData={setUnassignOptions}
                setChipsRemoved={setChipsRemoved}
                isDetailer={false}
              />
            </div>
            <div style={{display: 'flex', justifyContent: 'space-between', flexDirection: 'column', alignItems: 'flex-start', width: '100%',
              borderTop: unassignOptions?.designerDetail?.length > 0  ? '1px solid #EBEBEB' : 'none', padding: '8px 0px', marginBottom: '16px',
            }}>
              <p className='roboto-15-500' style={{paddingBottom: '8px', textAlign: 'initial'}}>{'Assigned for Detailing'}</p>
              <UnassignChips
                chipsData={unassignOptions?.designerDetail}                
                setChipsData={setUnassignOptions}
                setChipsRemoved={setChipsRemoved}
                isDetailer={true}
              />
            </div>
            <Button
              onClick={handleCancelEdit}
              style={{
                  position: 'absolute', right: '24px',
                  width: '24px',
                  height: '24px',
                  borderRadius: '180px',
                  backgroundColor: '#F2F2F2',
                  border: 'none',
                  padding: 0,
              }}> <SvgIcon icon={xIcon} color='#8D8D8D'></SvgIcon>
            </Button> 
          </div>
          <div style={{display: 'flex', justifyContent: 'flex-end', gap: '8px' }}>
            <button onClick={handleCancelEdit} className='secondary-button roboto-15-500 blue-text'>Cancel</button>
            <button onClick={handleAcceptUnassignation} className='primary-button roboto-15-500'>Save</button>
          </div>
        </div>
      </Dialog>
      )}

      <Modal
        visible={modalVisible}
        onClose={handleCancelEdit}
        title='Are you sure you want to assign these orders to yourself?'
        message={`${selectedOptions?.length} orders will be assigned to yourself as "${values}"`}
        onAccept={handleAcceptAssignation}
        type='INFORMATION'
      />

    </div>
  );
};

export default TabStripComponent;
