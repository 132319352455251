import React, { useState, useRef, useEffect } from 'react';
import { Button } from '@progress/kendo-react-all';
import { SvgIcon } from '@progress/kendo-react-common';
import { caretAltDownIcon, trashIcon, searchIcon } from "@progress/kendo-svg-icons";
import './FilterDropDown.css';
import useProfileData from '../services/ProfileDataService';
import axios from '../axiosConfig';
import { getCurrentLine, subscribeToCurrentLineChanges } from '../services/CurrentLineService';
import { InputPrefix, TextBox, InputSuffix } from "@progress/kendo-react-inputs";
import FilterSection from './FilterSection';
import { GENERAL_TAB } from '../Utilities/constants';

const FilterDropdown = ({ onApplyFilter, item, numFiltersApplied, selectedOptions, setSelectedOptions, handleShowMessage }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const modalRef = useRef(null);
  const { token } = useProfileData(false);  
  const [currentLine, setCurrentLine] = useState(getCurrentLine().ProdFamilyId);
  const [optionsData, setOptionsData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchText, setSearchText] = useState('');  
  const [filterTitle, setFilterTitle] = useState('');
  const [isInputVisible, setIsInputVisible] = useState(false);    

  useEffect(() => {
    const unsubscribe = subscribeToCurrentLineChanges(newCurrentLine => {
      setCurrentLine(newCurrentLine.ProdFamilyId);
    });
    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    fetchReferenceOptions();
  }, [currentLine, token]);

  const fetchReferenceOptions = async () => {
    if (token) {
      try {
        const codeNumbersResponse = await axios.get(
          `/selectionsByCodeNum?&prodFamilyId=${encodeURIComponent(currentLine)}&viewOption=${encodeURIComponent(GENERAL_TAB)}`,
          {
            headers: {
              Authorization: token,
            },
          }
        );

        // group data by `fiCodeItem` and allocate `cvOption`
        const groupedData = codeNumbersResponse.data.reduce((acc, current) => {
          const { fiCodeItem, cvOption, ...rest } = current;
          const existingItem = acc.find(item => item.fiCodeItem === fiCodeItem);

          if (existingItem) {
            if (Array.isArray(cvOption)) {
              cvOption.forEach(option => {
                if (!existingItem.cvOption.includes(option)) {
                  existingItem.cvOption.push(option);
                }
              });
            } else if (!existingItem.cvOption.includes(cvOption)) {
              existingItem.cvOption.push(cvOption);
            }
          } else {
            acc.push({
              fiCodeItem,
              cvOption: Array.isArray(cvOption) ? cvOption : [cvOption],
              ...rest
            });
          }

          return acc;
        }, []);

        setOptionsData(groupedData);
        setFilteredData(groupedData);

      } catch (error) {        
        setOptionsData([]);
        setFilteredData([]);
      }
    }
  };

  const numFilters = Object.keys(numFiltersApplied).length;

  // Toggle visibility of the modal
  const toggleModal = () => {
    setFilterTitle('');
    setIsInputVisible(false);
    setModalVisible(!modalVisible);

  };

  const handleCheckboxChange = (event, filter) => {
    const option = event.target.value;

    const newOption = {
      Value: option,
      codeStart: filter.fiStartPosition,
      codeLength: filter.fiLength,
      fiCodeItem: filter.fiCodeItem,
      description: filter.cvDescription,

    };

    setSelectedOptions(prev => {
      const exists = prev.find(item => item.Value === option && item.fiCodeItem === filter.fiCodeItem);
      return exists
        ? prev.filter(item => !(item.Value === option && item.fiCodeItem === filter.fiCodeItem))
        : [...prev, newOption];
    });
  };

  const applyFilter = () => {
    onApplyFilter(selectedOptions);
    setModalVisible(false);
  };

  // Close modal if clicked outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalVisible && modalRef.current && !modalRef.current.contains(event.target)) {
        setModalVisible(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [modalVisible]);

  const filterData = (event) => {
    //Reset filter when there are no characters
    if(event === "") setFilteredData(optionsData);

    const searchValue = event.target.value.toLowerCase();

    const newFilteredData = optionsData.filter((item) =>
      item.fiCodeItem.toString().includes(searchValue) ||
      item.cvOption.some(option => option.toLowerCase().includes(searchValue)) ||
      item.cvDescription.toLowerCase().includes(searchValue)
    );

    setFilteredData(newFilteredData);
  };

  const handleInputChange = (event) => {
    const value = event.target.value;
    setSearchText(value);
    filterData(event);
  };

  const clearSearch = () => {
    setSearchText('');
    filterData({ target: { value: '' } });
  };

  const handleSaveFilters = () => {
    // group selected options by 'description'
    const groupedFilters = selectedOptions.reduce((acc, option) => {
      const { description, Value, codeLength, codeStart, fiCodeItem } = option;

      if (!acc.filters[description]) {
        acc.filters[description] = [];
        acc.codeDetails[description] = [];
      }

      if (!acc.filters[description].includes(Value)) {
        acc.filters[description].push(Value);
      }

      if (!acc.codeDetails[description].some(detail =>
        detail.codeLength === codeLength &&
        detail.codeStart === codeStart &&
        detail.fiCodeItem === fiCodeItem)) {
        acc.codeDetails[description].push({
          codeLength,
          codeStart,
          fiCodeItem
        });
      }

      return acc;
    }, { filters: {}, codeDetails: {} });

    const newFilter = {
      title: filterTitle,
      lineNumber: currentLine,
      filters: groupedFilters.filters,
      codeDetails: groupedFilters.codeDetails
    };

    let savedFilters = JSON.parse(localStorage.getItem('savedFilters')) || [];

    if (savedFilters.length >= 10) {
      savedFilters.shift();
    }

    savedFilters.push(newFilter);
    localStorage.setItem('savedFilters', JSON.stringify(savedFilters));

    applyFilter();
    handleShowMessage("Your code string filters have been saved", "success");
  };

  return (
    <div className="filter-dropdown roboto-15-400 fs-17">
      <div>
        {getCurrentLine().PlantProdFamilyId && (
          <Button
            onClick={toggleModal}
            className="filter-button roboto-15-500 fs-15">
            <span className="return-text">Code String Filters</span>
            {numFilters > 0 ? ` (${numFilters})` : ' (0)'}
            <SvgIcon icon={caretAltDownIcon} size={'large'} color="black" />
          </Button>
        )}
      </div>
      {modalVisible && (
        <div className="modal-container">
          <div ref={modalRef} className="modal-content">
            <div className="modal-body row container">
              <div className="filter-container">
                <div className='col-8 search-container'>
                  <TextBox
                    className='roboto-15-400'
                    value={searchText}
                    onChange={handleInputChange}
                    placeholder="Search code item number or name"
                    prefix={() => (
                      <InputPrefix>
                        {/*icon if needed */}
                      </InputPrefix>
                    )}
                    suffix={() => (
                      searchText && (
                        <InputSuffix>
                          <button
                            className="clear-button close-icon"
                            onClick={clearSearch}
                            aria-label="Clear search"
                          >
                            <span className="material-symbols-outlined mr-3">
                              close
                            </span>
                          </button>
                        </InputSuffix>
                      )
                    )}
                  />
                </div>
                <Button onClick={applyFilter}
                  className='primary-button col-3 mb-2'
                  themeColor={"primary"}>
                  Apply Filters
                </Button>
              </div>
                    
              {filteredData?.length === 0 ? (
                <div className="roboto-error fs-17 text-center mt-3">
                  Format number not found for this product
                </div>
              ) : (
                filteredData
                  ?.sort((a, b) => a.fiCodeItem - b.fiCodeItem)
                  .map((filter, index) => (
                    <FilterSection
                      key={index}
                      title={filter.fiCodeItem + ". " + filter.cvDescription}
                      options={filter.cvOption}
                      selectedOptions={selectedOptions}
                      onCheckboxChange={(event) => handleCheckboxChange(event, filter)}
                      filter={filter}
                      setSelectedOptions={setSelectedOptions}
                    />
                  ))
              )}
            </div>

            {!isInputVisible ? (
              <div className="modal-footer">
                <Button onClick={() => setIsInputVisible(true)} className="apply-button return-button blue-text">
                  Save filters
                  <span className="material-symbols-outlined">
                    save
                  </span>
                </Button>
                <Button onClick={toggleModal} className="close-button return-button blue-text w-25">
                  Close
                </Button>
              </div>
            ) : (
              <div className="modal-footer" >
                <div className="d-flex align-items-center gap-10 w-100">
                  <TextBox
                    className='roboto-15-400'
                    value={filterTitle}
                    onChange={(e) => setFilterTitle(e.value)}
                    placeholder="Name your filter configuration"
                    suffix={() => (
                      filterTitle && (
                        <InputSuffix>
                          <button
                            className="clear-button close-icon"
                            onClick={() => setFilterTitle('')}
                            aria-label="Clear filter title"
                          >
                            <span className="material-symbols-outlined mr-5">close</span>
                          </button>
                        </InputSuffix>
                      )
                    )}
                  />
                  <Button onClick={handleSaveFilters} className="apply-button return-button blue-text">
                    Save filters
                    <span className="material-symbols-outlined">
                      save
                    </span>
                  </Button>

                  <Button onClick={toggleModal} className="close-button blue-text">
                    Close
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default FilterDropdown;
