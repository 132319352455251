import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";
import { EmployeeRoleProvider } from "./services/EmployeeRoleContext";

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

/**
 * Initialize a PublicClientApplication instance which is provided to the MsalProvider component
 * We recommend initializing this outside of your root component to ensure it is not re-initialized on re-renders
 */
const msalInstance = new PublicClientApplication(msalConfig);

root.render(
  <BrowserRouter basename={baseUrl}>
        <React.StrictMode>
        <MsalProvider instance={msalInstance}>
          <EmployeeRoleProvider>
              <App />
          </EmployeeRoleProvider>
        </MsalProvider>
    </React.StrictMode>,
  </BrowserRouter>);